import React, {lazy, Suspense, useState} from 'react';
import WebDevelopmentHeader from "../components/WebDevelopmentPage/WebDevelopmentHeader/WebDevelopmentHeader";
import {webTechnologiesIconImagesArr} from "../assets/UIData";

import Head from '../components/global/Head/Head';

const WebDevelopmentProcess = lazy(() => import("../components/WebDevelopmentPage/WebDevelopmentProcess/WebDevelopmentProcess"));
const WebDevelopmentWrapperWithImg = lazy(() => import("../components/WebDevelopmentPage/WebDevelopmentWrapperWithImg/WebDevelopmentWrapperWithImg"));
const TechnologiesBlock = lazy(() => import("../components/global/TechnologiesBlock/TechnologiesBlock"));
const BlogSlider = lazy(() => import("../components/global/BlogSlider/BlogSlider"));


function WebDevelopmentPage() {
    const [headerImageLoaded, setHeaderImageLoaded] = useState(false)

    const onLoadHeaderImage = () => setHeaderImageLoaded(true)

    return (
        <>
            <Head
                keyword={'web design, web technologies, web services, responsive web design, website redesign, web development, mobile development, frontend development, backend development, full-stack development, website maintenance, tech consulting, technical support'}
                ogKeyword={'web design, web technologies, web services, responsive web design, website redesign, web development, mobile development, frontend development, backend development, full-stack development, website maintenance, tech consulting, technical support'}

                title={'Website Design and Development Services | KHG tec'}
                ogTitle={'Website Design and Development Services | KHG tec'}

                description={'Our range of services includes tech consulting, responsive web design, redesign and migration, full-stack development, ongoing maintenance, and more.'}
                ogDescription={'Our range of services includes tech consulting, responsive web design, redesign and migration, full-stack development, ongoing maintenance, and more.'}
            />

            <WebDevelopmentHeader onLoadHeaderImage={onLoadHeaderImage}/>
            <Suspense fallback={null}>
                <WebDevelopmentProcess headerImageLoaded={headerImageLoaded}/>
            </Suspense>
            <Suspense fallback={null}>
                <WebDevelopmentWrapperWithImg/>
            </Suspense>
            <Suspense fallback={null}>
                <TechnologiesBlock iconImagesArr={webTechnologiesIconImagesArr}/>
            </Suspense>
            <Suspense fallback={null}>
                <BlogSlider type={'web'}/>
            </Suspense>
        </>
    );
}

export default WebDevelopmentPage;