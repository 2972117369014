import React, {lazy, Suspense} from 'react';

const JoinForm = lazy(() => import("../components/JoinPage/JoinForm/JoinForm"));


function JoinPage() {
    return (
        <Suspense fallback={null}>
            <JoinForm/>
        </Suspense>
    );
}

export default JoinPage;