import {CLEAR_IND_BLOG_DATA, GET_IND_BLOG_FAIL, GET_IND_BLOG_LOADING_START, GET_IND_BLOG_SUCCESS} from "../types";


const initialState = {
    data: null,
    loading: false,
    error: null,
}

export const indBlogReducer = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case GET_IND_BLOG_SUCCESS :
            return {
                ...state,
                data: payload,
                loading: false,
            }
        case GET_IND_BLOG_FAIL :
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case GET_IND_BLOG_LOADING_START :
            return {
                ...state,
                loading: true,
            }
        case CLEAR_IND_BLOG_DATA :
            return {
                ...state,
                data: null,
                loading: false,
                error: null,
            }
        default:
            return state
    }
}