export const arrowDownIcon = 'arrowDown'
export const burgerIcon = 'burger'
export const crossIcon = 'cross'
export const facebookIcon = 'facebook'
export const instagramBlackIcon = 'instagramBlackIcon'
export const instagramBlueIcon = 'instagramBlueIcon'
export const linkedInIcon = 'linkedIn'
export const behanceIcon = 'behance'
export const whatsappIcon = 'whatsapp'
export const upworkIcon = 'upwork'
export const messageIcon = 'message'
export const phoneIcon = 'phone'
export const addressIcon = 'address'
export const navLogoIcon = 'navLogo'
export const frontEndIcon = 'frontEndIcon'
export const backendIcon = 'backendIcon'
export const supportIcon = 'supportIcon'
export const musicPrevIcon = 'musicPrev'
export const musicNextIcon = 'musicNext'
export const musicPlayIcon = 'musicPlay'
export const musicStopIcon = 'musicStop'
export const subscribePopupCross = 'subscribePopupCross'
export const attachFileIcon = 'attachFile'