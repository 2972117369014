import React from 'react';

import styles from "./UiUxDesignHeader.module.scss"
import {uiUxDesignHeaderImg, uiUxDesignHeaderImgWebp} from "../../../assets/images";
import {useImageSlideAnimation} from "../../../hooks/useImageSlideAnimation";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {useDispatch, useSelector} from "react-redux";
import {SET_DESIGN_HEADER_IMAGE_HEIGHT} from "../../../redux/types";

function UiUxDesignHeader() {
    const dispatch = useDispatch()
    const designHeaderImageHeight = useSelector(state => state.UI.designHeaderImageHeight)

    const setDesignHeaderImageHeight = payload => dispatch({type: SET_DESIGN_HEADER_IMAGE_HEIGHT,payload})

    const {
        imgRef,
        imgListHeight,
        changeImgListHeight,
        ImageSlideAnimation
    } = useImageSlideAnimation(designHeaderImageHeight,setDesignHeaderImageHeight,3)

    const onImgLoad = (e) => {
        changeImgListHeight(3,30)
        ImageSlideAnimation(e, 7000,'-66.67% + 30px')
    }

    return (
        <header className={styles['uiUxDesignHeader']}>
            <div className={`${styles["uiUxDesignHeader__container"]} container`}>
                <h2 className={`${styles['uiUxDesignHeader__title']} headerTitle`}>Elevating User Experiences with<span className="lightBlueText"> Intuitive</span> Interfaces</h2>


                <div className={`${styles["uiUxDesignHeader__imgBlock"]} lightBlueBorderBox`} style={{height: imgListHeight}}>
                    <ImageWebp
                        pictureClass={styles["uiUxDesignHeader__picture"]}
                        forwardedRef={imgRef}
                        srcSet={uiUxDesignHeaderImgWebp}
                        src={uiUxDesignHeaderImg}
                        alt="Ui UX design"
                        className={styles["uiUxDesignHeader__img"]}
                        onLoad={onImgLoad}
                    />
                </div>
            </div>
        </header>
    );
}

export default UiUxDesignHeader;
