import React, {lazy, Suspense} from 'react';
import BlogHeader from "../components/BlogPage/BlogHeader/BlogHeader";
import Head from '../components/global/Head/Head'

const BlogList = lazy(() => import("../components/BlogPage/BlogList/BlogList"));

function BlogPage() {
    return (
        <>
            <Head
                keyword={'latest tech blogs, technology news, tech trends, IT industry updates, innovations, robotics blog, cybersecurity, artificial intelligence, machine learning, data analytics,'}
                ogKeyword={'latest tech blogs, technology news, tech trends, IT industry updates, innovations, robotics blog, cybersecurity, artificial intelligence, machine learning, data analytics,'}

                title={'Tech Blogs: Latest Technology News and Trends | KHG tec'}
                ogTitle={'Tech Blogs: Latest Technology News and Trends | KHG tec'}

                description={'Stay updated with the latest tech trends, news, and insights in our technology blog. Explore informative articles, tips, and industry updates in the IT sector.'}
                ogDescription={'Stay updated with the latest tech trends, news, and insights in our technology blog. Explore informative articles, tips, and industry updates in the IT sector.'}
            />

            <BlogHeader/>
            <Suspense fallback={null}>
                <BlogList/>
            </Suspense>
        </>
    );
}

export default BlogPage;