import {
    CLEAR_IND_BLOG_DATA,
    GET_IND_BLOG_LOADING_START,
    GET_IND_BLOG_SUCCESS
} from "../types";
import {fetchRequest, mainBlogUrl} from "./fetchTools";


export const getIndBlog = (id) => async (dispatch) => {
    dispatch({type: GET_IND_BLOG_LOADING_START})
    try {
        const fetchData = await fetchRequest(`${mainBlogUrl}/${id}` )
        dispatch({type: GET_IND_BLOG_SUCCESS,payload: fetchData.data})
    } catch (err) {
        throw new Error(err)
    }
}


export const clearIndBlogData = () => dispatch => {
    dispatch({type: CLEAR_IND_BLOG_DATA})
}