import React from "react";
import {
    aboutCompany1,
    aboutCompany1Webp,
    aboutCompany2,
    aboutCompany2Webp,
    appDevelopmentAboutMobile,
    appDevelopmentAboutMobileWebp,
    homeAboutAppDev,
    homeAboutAppDevGif,
    homeAboutSeo,
    homeAboutSeoGif,
    homeAboutWeb,
    homeAboutWebGif,
    uiIxDesignWrapperWithImg1,
    uiIxDesignWrapperWithImg1Poster,
    uiIxDesignWrapperWithImg2,
    uiIxDesignWrapperWithImg2Poster,
    webDevAboutImg,
    webDevAboutImgWebp,
    techAngularIcon,
    techPhpIcon,
    techYiiIconWebp,
    techJqueryIconWebp,
    techJqueryIcon,
    techNodeIconWebp,
    techAngularIconWebp,
    techVueIcon,
    techCodeIgniterIcon,
    techCssIcon,
    techHtmlIcon,
    techYiiIcon,
    techVueIconWebp,
    techReactIcon,
    techNodeIcon,
    techJsIconWebp,
    techJsIcon,
    techLaravelIcon,
    techLaravelIconWebp,
    techHtmlIconWebp,
    techPhytonIcon,
    techReactIconWebp,
    techPhpIconWebp,
    techCodeIgniterIconWebp,
    techCssIconWebp,
    techPhytonIconWebp,
    techCIcon,
    techCIconWebp,
    techAndroidIcon, techAndroidIconWebp,
    techSwiftIcon,
    techSwiftIconWebp,
    techIosIcon,
    techIosIconWebp,
    techReactNativeIcon,
    techReactNativeIconWebp,
    seoServicesNotebook,
    seoServicesNotebookWebp, seoUxWritingGifPoster, seoUxWritingGif, techGoIcon, techGoIconWebp,
} from "./images";
import {
    backendIcon,
    frontEndIcon,
    supportIcon
} from "./svg";

export const homeWebDevText = {
    imgSrc: homeAboutWeb,
    gifImg: homeAboutWebGif,
    textBlockSize: 'md',
    isWithBtn: true,
    titleText: <>Custom<span className='lightBlueText'> Web Development</span></>,
    contentTextArr: [
        <>
            Utilizing custom-built and adaptive approaches to web development, we provide websites that are specially
            designed, stable, and reliable.
        </>,
        <>
            Being our customer, you will always have the opportunity to take part in every step and track our common
            progress.
        </>
    ],
}

export const homeAppDevText = {
    isReverse: true,
    imgSrc: homeAboutAppDev,
    gifImg: homeAboutAppDevGif,
    textBlockSize: 'md',
    isWithBtn: true,
    titleText: <>Comprehensive<span className='lightBlueText'> App Development</span></>,
    contentTextArr: [
        'Incorporating the newest technology, our expert developers produce feature-packed, secure mobile and desktop apps, ensuring users a pleasurable app experience.'
    ],
}

export const homeSeoText = {
    gifPoster: homeAboutSeo,
    gifImg: homeAboutSeoGif,
    textBlockSize: 'md',
    isWithBtn: true,
    titleText: <> <span className='lightBlueText'>SEO Ranking</span> and <span
        className='lightBlueText'>UX Writing</span></>,
    contentTextArr: [
        <>
            Custom-built websites are empowered to maintain top positions in search engine rankings through strategic
            optimization.
        </>,
        <>
            The thoughtfully crafted copy by our UX writers contributes to the development of the website and assists
            the audience in finding it in search engines.
        </>
    ],
}

export const designText = {
    gifPoster: uiIxDesignWrapperWithImg1Poster,
    isReverse: true,
    gifImg: uiIxDesignWrapperWithImg1,
    titleText: <>Web and Mobile app<span className='lightBlueText'> UX/UI Design</span></>,
    contentTextArr: [
        <>
            We adopt a user-centered approach to design, giving considerable importance to intuitive functionality.
            <br/>
            The services we provide:
        </>,
        <>
            <span className="lightBlueText">Wireframes and Mockups, Clickable Prototypes</span>
            <span>Designed to offer a visual representation of a mobile or web app, wireframes and mockups showcase the content, structure, and functionality that will appear on the app or software's pages.
            <br/>
             We provide early-stage opportunities to clients to test the functionality of UX/UI before creating the final designs.
            <br/>
          Prototyping is a crucial element for successful products!
            </span>
        </>,
        <>
            <span className="lightBlueText">Visual Design</span>
            <span>Our services range from brand identity design to web design, user interface design, illustration and animation, and more.</span>
        </>
    ],
}

export const designResponsiveText = {
    gifPoster: uiIxDesignWrapperWithImg2Poster,
    gifImg: uiIxDesignWrapperWithImg2,
    titleText: <><span className='lightBlueText'>UX/UI</span> Optimization</>,
    contentTextArr: [
        <>
            The aim of UX optimization is to improve user interactions with apps and websites by creating practical,
            user-friendly, and appealing experiences.
            <br/>
            We offer various optimization services, including:
        </>,
        <>
            <span className="lightBlueText">Multi-platform Optimization</span>
            <span>Design optimization for multiple devices requires the use of responsive design, which includes simplified navigation, easy-to-read content, optimized images for small screens, and numerous other techniques.</span>
        </>,
        <>
            <span className="lightBlueText">Decreasing Loading Time</span>
            <span>By minimizing HTTP requests, caching, optimizing code, compressing files, and conducting a thorough assessment of third-party tools, we create websites and applications that load rapidly, ensuring a superior user experience.
            </span>
        </>,
        <>
            <span className="lightBlueText">Usability Testing</span>
            <span>Via a range of usability testing services, usability issues can be identified and addressed to enhance  the user experience of digital products.</span>
        </>,
    ],
}

export const particleOptions = {
    background: {
        color: {
            value: "#00D8FF",
        },
    },
    fullScreen: false,
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
            onHover: {
                enable: true,
                mode: "repulse",
            },
            resize: true,
        },
        modes: {
            push: {
                quantity: 4,
            },
            repulse: {
                distance: 200,
                duration: 0.4,
            },
        },
    },
    particles: {
        color: {
            value: "#ffffff",
        },
        links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            directions: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 3,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 80,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: {min: 1, max: 5},
        },
    },
    detectRetina: true,
}

// about wrapperWithImg 1

export const aboutWrapperWithImg1 = {
    isReverse: true,
    img: aboutCompany1,
    imgWebp: aboutCompany1Webp,
    textBlockSize: 'lg',
    titleText: <>What we <span className='lightBlueText'>do</span></>,
    contentTextArr: [
        <>
            We are a full-service web and mobile app development company.
            <br/>
            At KHG tec, we develop solutions that are easy to adapt to a variety of platforms and devices. Our
            experienced scrum masters ensure the implementation of Agile Methodology for successful project completion
            and timely delivery.
            <br/>
            Below are some of the essential services that are offered by us:
        </>,
        <>
            <span className="lightBlueText">Design, Full-Cycle Development</span>
            <span>Upon completing the planning and design stages, we provide an extensive range of development services,  which encompass front-end and back-end development, as well as database schema design, SEO, and more.</span>
        </>,
        <>
            <span className="lightBlueText">Quality Control and Testing</span>
            <span>The created products undergo a thorough quality assurance process that includes manual and automated testing, usability assessments, and security evaluations.</span>
        </>,
        <>
            <span className="lightBlueText">Launch and Support</span>
            <span>Following the website's publication, DevOps services are utilized, which involve continuous monitoring, automation, optimization, and security enhancements to guarantee uninterrupted website operation and maximum uptime.
</span>
        </>,
    ],
    isMobileReverse: true,
}

export const aboutWrapperWithImg2 = {
    isReverse: false,
    img: aboutCompany2,
    imgWebp: aboutCompany2Webp,
    textBlockSize: 'lg',
    titleText: <>Our <span className='lightBlueText'>goals</span></>,
    contentTextArr: [
        <>
            As a beginning-to-end software development provider, we place a strong emphasis on our clients' unique
            business peculiarities and challenges, paying attention to details and quality.
        </>,
        <>
            The goal of our professional and dedicated team is to create applications that leave a positive impression
            on users and simplify the process of finding websites on the Internet for potential clients, encouraging
            them to visit them.
        </>,
        <>
            By aligning our efforts with these goals, we aim to be a leading web and app development company that
            consistently delivers exceptional results to our clients, while maintaining high standards of quality,
            innovation, and consumer delight.
            <br/>
            We always treat our customers' projects as if they were our own!
        </>
    ],
    isMobileReverse: true,
}

//FAQ Accordion

export const FAQAccordionArr = [
    {
        title: 'How long does it take to build a website?',
        contentText:
              'It usually depends on the complexity and scale of the project. But our team has expert developers who are well-versed, and no matter how complex the project is, they can meet your deadlines. The time and speed also depend on how many initial inputs you provide and your availability with feedback.'
    },

    {
        title: 'How much does a new website cost?',
        contentText:
            'There\'s not one single answer to this question. A website design is quoted based on the needs of each individual project. Every website is unique and requires different components. Most of our sites run in the $5,000 range, but can be more or less depending on needs.'
    },

    {
        title: 'When do I pay?',
        contentText:
            'For most projects, equal payments are made at the start, midway, and the end of the project, but we can work with you to set up a schedule that meets your needs. We understand that this is a big investment and want to help you budget for the expense in whatever way possible. We accept checks, PayPal, and all major credit cards.'
    },

    {
        title: 'Can you help me write content for my website?',
        contentText: 'Of course, our team of copywriters can help you in delivering professional content by understanding your requirements and business objectives.'
    },

    {
        title: 'I already have a website, how easy is it to change it?',
        contentText:
            'The difficulty level of updating an already existing one depends on the required changes. If the changes are complex, it may take time to deliver. However, for minor changes, they can be handled and delivered promptly.'
    },

    {
        title: 'Can you design my logo?',
        contentText:
            'Yes, we can help you in designing your logo that suits your business as we have expert designers working for us. We ensure your logo to be unique, creative and attractive.'
    },

    {
        title: 'Will my website be mobile-friendly?',
        contentText: 'Absolutely! We work hard to ensure your website looks great on a variety of devices.'
    },

    {
        title: 'Do you do SEO for my website when you build it?',
        contentText:
           'Yes, we do optimize your website for SEO as it is one of the crucial steps that will increase the possibility of getting many leads.'
    },

    {
        title: 'How long will it take to get to the 1st page of Google?',
        contentText:
           'Normally it would take a minimum of 6 months for your website to appear on the 1st page of Google, and it completely depends on the difficulty of the keywords used in your website. It is quite unpredictable as nowadays the competitors are quite high and almost every business is keen on establishing SEO optimized websites.'
    },

    {
        title: 'Can You Build Apps for Both iOS and Android?',
        contentText:
            'At KHG tec, we build apps that support both iOS and Android platforms.'
    },

    {
        title: 'Will you maintain my site for me?',
        contentText:
            'We can! We provide ongoing support for many of our clients.'
    },

    {
        title: 'Where should my website be hosted?',
        contentText:
            'We\'ve developed a list of our absolute favorite hosts to help you find the right host at the right price.'
    },

    {
        title: 'How do you ensure the security of web and mobile applications in a DevOps environment?',
        contentText:
            'Security is a top priority in our DevOps environment. We employ best practices for secure coding, regularly conduct security audits and penetration testing, and use tools like vulnerability scanners and intrusion detection systems. We also ensure that all systems and applications are regularly updated with the latest security patches and upgrades.'
    },
]

//Privacy Policy

export const privacyAccordionArr = [
    {
        title: 'How to Write a Privacy Policy',
        contentText:
            <>
                <p>An important aspect of our services includes direct communication with our customers. As a result, we
                    will periodically send you communications to your email, text, or voice mailbox, via telephone, fax,
                    cell phone, email, paper mail, or any other delivery method regarding the products or services you
                    may have purchasedthrough this Website. We may also send you information about product or service
                    updates, new features, or information we believe you may find interesting.
                </p>
                <ul>
                    <li>
                        Research the specific requirements for your business depending on the audience, region, and
                        third-party services used
                    </li>
                    <li>Familiarize yourself with policies from similar sites, but don’t plagiarize</li>
                    <li>Take the extra precaution of hiring a lawyer to look it over</li>
                    <li>
                        This Privacy Policy does not address, and we are not responsible for, the privacy, information
                        or other practices of any third parties, including any third-party site or service used in
                        connection with our products and services. The inclusion of a link does not imply endorsement of
                        the linked site or service by us.
                    </li>
                    <li>
                        The Services are controlled and operated by us from the United States and are not intended to
                        subject us to the laws or jurisdiction of any state, country or territory other than that of the
                        United States. Your personal information may be stored and processed in any country where we
                        have facilities or in which we engage service providers, and by using our services you
                        understand that your information will be transferred to countries outside of your country
                    </li>
                </ul>
                <p>
                    For your protection, we may only implement requests with respect to the personal information
                    associated with the particular email address that you use to send us your request, and we may need
                    to verify your identity before implementing your request. We will try to comply with your request as
                    soon as reasonably practicable. We may use the information you provide for marketing purposes such
                    as promotional emailing, direct mail, and sales contacts. We give you many choices regarding our use
                    and disclosure of your personal information for marketing purposes. You may opt-out from receiving
                    electronic communications from us if you are a user of products or services and no longer.
                </p>
            </>
    },
    {
        title: 'Keep Your Privacy Policy Up To Date',
        contentText:
            <>
                <ul>
                    <li>
                        Research the specific requirements for your business depending on the audience, region, and
                        third-party services used
                    </li>
                    <li>Familiarize yourself with policies from similar sites, but don’t plagiarize</li>
                    <li>Take the extra precaution of hiring a lawyer to look it over</li>

                </ul>
                <p>
                    Our prices for a basic mobile-friendly small business website, with a content management system,
                    start from £1,000. For a unique, bespoke design with more extensive functionality, prices start from
                    around £3,000. Bespoke websites are costed after having spoken with you in some depth about your
                    requirements and creating a solution exactly for you.
                </p>

                <p>
                    The cost will also be dependent on the type and experience of the provider - a freelancer is usually
                    cheaper than an agency due to reduced overheads, but the agency can offer wide expertise than just
                    one person and often offers more reliability and better response times.
                </p>
                <p>
                    As a agency, we would always wo rk with you to establish the best approach for your business and
                    your
                    budget and provide you with a fixed cost and payment plan upfront.
                </p>
                <ul>
                    <li>The Website and Software may contain links that redirect to other websites. For example, when
                        accessing services of a third party such as PayPal when making a payment. This Policy does not
                        apply to such third-party websites, which TextMagic does not operate, and TextMagic does not
                        accept any responsibility or liability for these policies. TextMagic advises reviewing the
                        privacy policies of those third parties.
                    </li>
                    <li>What you use and how you choose to update visitors when your privacy policy gets updated</li>
                    <li>Protect against misuse or unauthorized use of Our Services</li>
                    <li>The Website and Software may contain links that redirect to other websites. For example, when
                        accessing services of a third party such as PayPal when making a payment. This Policy does not
                        apply to such third-party websites, which TextMagic does not operate, and TextMagic does not
                        accept any responsibility or liability for these policies. TextMagic advises reviewing the
                        privacy policies of those third parties.123
                    </li>
                </ul>
                {/*<p>
                    Our prices for a basic mobile-friendly small business website, with a content management system,
                    start from £1,000. For a unique, bespoke design with more extensive functionality, prices start from
                    around £3,000. Bespoke websites are costed after having spoken with you in some depth about your
                    requirements and creating a solution exactly for you.
                </p>*/}
            </>
    },
    {
        title: 'Website Privacy Policy Requirements',
        contentText:
            <>
                <p>The Website and Software may contain links that redirect to other websites. For example, when
                    accessing services of a third party such as PayPal when making a payment. This Policy does not apply
                    to such third-party websites, which TextMagic does not operate, and TextMagic does not accept any
                    responsibility or liability for these policies. TextMagic advises reviewing the privacy policies of
                    those third parties.
                </p>
                <p>
                    The cost will also be dependent on the type and experience of the provider - a freelancer is usually
                    cheaper than an agency due to reduced overheads, but the agency can offer wide expertise than just
                    one person and often offers more reliability and better response times.
                </p>
                <p>
                    As a agency, we would always work with you to establish the best approach for your business and your
                    budget and provide you with a fixed cost and payment plan upfront.
                </p>
                <p>
                    Our prices for a basic mobile-friendly small business website, with a content management system,
                    start from £1,000. For a unique, bespoke design with more extensive functionality, prices start from
                    around £3,000. Bespoke websites are costed after having spoken with you in some depth about your
                    requirements and creating a solution exactly for you.
                </p>
            </>
    }
]

//Terms Policy

export const termsAccordionArr = [
    {
        title: 'Use of the website and user obligations',
        contentText:
            <>
                <p>Terms and conditions agreement outlines the website administrator’s rules regarding user behavior and
                    provides information about the actions the website administrator can and will perform. If you plan
                    to grow your business or expand your user base, a simple website terms and conditions page will
                    provide your site with an additional layer of legal protection. Many companies use their website’s
                    terms and conditions to lay down the rules that users must agree to and follow before accessing your
                    website or service.
                </p>
                <ul>
                    <li>To Outline Policies and Avoid Abusive Behavior</li>
                    <li>Keep Others From Copying Your Content</li>
                    <li>To Outline Governing and Applicable Laws</li>
                    <li>Governing and Applicable Laws</li>
                </ul>
                <p>
                    The cost will also be dependent on the type and experience of the provider - a freelancer is usually
                    cheaper than an agency due to reduced overheads, but the agency can offer wide expertise than just
                    one person and often offers more reliability and better response times.
                </p>
                <p>
                    As a agency, we would always work with you to establish the best approach for your business and your
                    budget and provide you with a fixed cost and payment plan upfront.
                </p>
                <p>
                    For your protection, we may only implement requests with respect to the personal information
                    associated with the particular email address that you use to send us your request, and we may need
                    to verify your identity before implementing your request. We will try to comply with your request as
                    soon as reasonably practicable. We may use the information you provide for marketing purposes such
                    as promotional emailing, direct mail, and sales contacts. We give you many choices regarding our use
                    and disclosure of your personal information for marketing purposes. You may opt-out from receiving
                    electronic communications from us if you are a user of products or services and no longer.
                </p>
            </>
    },
    {
        title: 'Disclaimer and Warranty',
        contentText:
            <>
                <ul>
                    <li>Abusive users: Terms and Conditions agreements allow you to establish what constitutes
                        appropriate activity on your site or app, empowering you to remove abusive users and content
                        that violates your guidelines.
                    </li>
                    <li>Intellectual property theft: Asserting your claim to the creative assets of your site in your
                        terms and conditions will prevent ownership disputes and copyright infringement.
                    </li>
                    <li>Potential litigation: If a user lodges a legal complaint against your business, showing that
                        they were presented with clear terms and conditions before they used your site will help you
                        immensely in court.
                    </li>
                </ul>
                <p>
                    Our prices for a basic mobile-friendly small business website, with a content management system,
                    start from £1,000. For a unique, bespoke design with more extensive functionality, prices start from
                    around £3,000. Bespoke websites are costed after having spoken with you in some depth about your
                    requirements and creating a solution exactly for you.
                </p>
                <p>A disclaimer states that your site is available on an “as-is and as-available basis” and that users
                    use it at their own risk. Essentially, this clause states that you are not responsible for any
                    damage that your service, product, or website may potentially cause a user.
                </p>
            </>
    },
    {
        title: 'Reasons to Have a Terms and Conditions',
        contentText:
            <>
                <ul>
                    <li>Duration of licenses you grant.</li>
                    <li>License to other users.</li>
                    <li>Rights you grant to YouTube and other users of the service.</li>
                </ul>
                <p>A terms and conditions agreement outlines the website administrator’s rules regarding user behavior
                    and provides information about the actions the website administrator can and will perform.
                    Essentially, your terms and conditions text is a contract between your website and its users. In the
                    event of a legal dispute, arbitrators will look at it to determine whether each party acted within
                    their rights. Adding an intellectual property disclosure clause to your terms and conditions text
                    informs users of your intellectual property rights, such as your logos, content, and other protected
                    ideas and marks.
                </p>
                <p>
                    The cost will also be dependent on the type and experience of the provider - a freelancer is usually
                    cheaper than an agency due to reduced overheads, but the agency can offer wide expertise than just
                    one person and often offers more reliability and better response times.
                </p>
                <p>
                    Many companies use their website’s terms and conditions to lay down the rules that users must agree
                    to and follow before accessing your website or service. Most of these rules revolve around how users
                    should interact with others and what they can and..
                </p>
            </>
    }
]

// webDevelopmentProcess

export const processBoxesArr = [
    {
        icon: frontEndIcon,
        title: 'Front-end',
        contentText:
            'During client-side development, we implement a design layout with HTML5 markup, either from scratch or using the Bootstrap framework, apply visual styling via CSS3 through the Sass preprocessor, optimize images, create animations, add interactivity with JavaScript functions. To simplify website building, improve performance, flexibility and enhance speed we employ frameworks like React JS, Angular JS, and Vue.js.'
    },
    {
        icon: backendIcon,
        title: 'Back-end',
        contentText:
            'Depending on the specific requirements of the project, we choose the most appropriate server-side languages, such as PHP or Python, and frameworks including Laravel, Django, Node.js, that offer additional security features. To achieve advanced customization, we use Apache or NGINX servers and utilize widely-used databases like MongoDB, MariaDB, Redis, PostgreSQL, MySQL, and SQLite for effective data management and retrieval.'
    },
    {
        icon: supportIcon,
        title: 'Support',
        contentText:
            'Our development support services encompass a wide range of areas, \n' +
            'including software development, application maintenance, system integration, and more. \n' +
            'We leverage the latest technologies and best practices to deliver tailored solutions  \n' +
            'that align with your unique business objectives. By collaborating closely with your team,  \n' +
            'we ensure a deep understanding of your specific needs and provide personalized support that drives tangible results.'
    },
]

//webDevelopmentAbout

export const webDevelopmentAboutTexts = {
    img: webDevAboutImg,
    imgWebp: webDevAboutImgWebp,
    titleText: <>Web Development<span className='lightBlueText'> offerings</span></>,
    contentTextArr: [
        <>
            We design and develop websites at the highest standards of usability, accessibility, and versatility, while
            also delivering secure and scalable web development solutions.
            <br/>
            Our offerings also consist of additional services:
        </>,
        <>
            <span className="lightBlueText">Tech Consulting</span>
            <span>Consultants conduct a comprehensive assessment of client requirements, recommending optimal technology solutions and addressing potential risks that may arise, including data security, system failures, and other related issues.</span>
        </>,
        <>
            <span className="lightBlueText">Website Redesign and Migration</span>
            <span>This involves redesigning and migrating existing websites to modern technologies, frameworks, and platforms to improve their functionality, design, and performance.</span>
        </>,
        <>
            <span className="lightBlueText">Maintenance and Continuous Improvement</span>
            <span>Entails regularly updating the website, making any necessary modifications, and resolving any issues that may occur.</span>
        </>
    ],
}

// webDevelopmentTechnologies
export const webTechnologiesIconImagesArr = [
    {
        img: techPhpIcon,
        webp: techPhpIconWebp
    },
    {
        img: techLaravelIcon,
        webp: techLaravelIconWebp
    },
    {
        img: techYiiIcon,
        webp: techYiiIconWebp
    },
    {
        img: techCodeIgniterIcon,
        webp: techCodeIgniterIconWebp
    },
    {
        img: techJsIcon,
        webp: techJsIconWebp
    },
    {
        img: techReactIcon,
        webp: techReactIconWebp
    },
    {
        img: techVueIcon,
        webp: techVueIconWebp
    },
    {
        img: techAngularIcon,
        webp: techAngularIconWebp
    },
    {
        img: techNodeIcon,
        webp: techNodeIconWebp
    },
    {
        img: techJqueryIcon,
        webp: techJqueryIconWebp
    },
    {
        img: techCssIcon,
        webp: techCssIconWebp
    },
    {
        img: techHtmlIcon,
        webp: techHtmlIconWebp
    },
    {
        img: techGoIcon,
        webp: techGoIconWebp
    },
    {
        img: techPhytonIcon,
        webp: techPhytonIconWebp
    },
]

//app development

export const appDevelopmentAboutMobileTexts = {
    isReverse: true,
    img: appDevelopmentAboutMobile,
    imgWebp: appDevelopmentAboutMobileWebp,
    titleText: <><span className='lightBlueText'>Mobile</span> App Development</>,
    contentTextArr: [
        <>
            Along with security and privacy measures, we provide a full package of mobile application development
            services that incorporates mobile app SEO.
        </>,
        <>
            <span className="lightBlueText">App Design and Development</span>
            <span>UX/UI design involves creating visually appealing interfaces and user experiences that are optimized for mobile devices, with a focus on intuitive navigation and ease of use.</span>
        </>,
        <>
            By using specialized programming languages and frameworks, we assure cross-platform compatibility on iOS and Android platforms.
        </>,
        <>
            <span className="lightBlueText">Debugging, Deploying and Support</span>
            <span>Following the completion of testing, the App is deployed on the Apple App Store, Google Play Store, enabling users to download and use it.</span>
        </>,
        <>
            After the deployment of the app, the services include providing continuous support and maintenance to ensure that the app stays operational and up-to-date.
        </>,
    ],
}

export const appTechnologiesIconImagesArr = [
    {
        img: techPhpIcon,
        webp: techPhpIconWebp
    },
    {
        img: techLaravelIcon,
        webp: techLaravelIconWebp
    },
    {
        img: techYiiIcon,
        webp: techYiiIconWebp
    },
    {
        img: techCodeIgniterIcon,
        webp: techCodeIgniterIconWebp
    },
    {
        img: techJsIcon,
        webp: techJsIconWebp
    },
    {
        img: techReactNativeIcon,
        webp: techReactNativeIconWebp
    },
    {
        img: techAngularIcon,
        webp: techAngularIconWebp
    },
    {
        img: techNodeIcon,
        webp: techNodeIconWebp
    },
    {
        img: techAndroidIcon,
        webp: techAndroidIconWebp
    },
    {
        img: techIosIcon,
        webp: techIosIconWebp
    },
    {
        img: techSwiftIcon,
        webp: techSwiftIconWebp
    },
    {
        img: techCIcon,
        webp: techCIconWebp
    },
    {
        img: techPhytonIcon,
        webp: techPhytonIconWebp
    },
]


//seoServices
export const seoServicesTexts = {
    isReverse: true,
    img: seoServicesNotebook,
    imgWebp: seoServicesNotebookWebp,
    titleText: <><span className='lightBlueText'>SEO </span> services that grow traffic</>,
    contentTextArr: [
        <>
            We provide all of the services and technology your business needs to grow with SEO.
        </>,
        <>
            <span className="lightBlueText">Technical SEO</span>
            <span>Rankings are accomplished by us through increasing the page loading speed by optimizing image size, refining the server-side of the website, and implementing various other methods.
            </span>
        </>,
        <>
            <span className="lightBlueText">On-Page SEO</span>
            <span>Our SEO experts publish unique, value-rich content, optimize headlines and HTML tags, select meta titles and descriptions with the right keywords, and employ persuasive Call to Actions to boost Click-Through Rates from search engine results.
            </span>
        </>,
        <>
            <span className="lightBlueText">SEO Analytics</span>
            <span>As part of our services, we offer monitoring of keyword rankings, analysis of website traffic, and identification of areas for improvement to enhance online visibility and attract more traffic.</span>
        </>,
    ],
}

export const seoUxWritingTexts = {
    imgSrc: seoUxWritingGifPoster,
    gifImg: seoUxWritingGif,
    titleText: <>Expert<span className='lightBlueText'> UX Writing</span> services</>,
    contentTextArr: [
        <>
            UX Writing services are intended to improve the usability, clarity, and effectiveness of the product's
            interface.
            <br/>
            Among the services we offer are:
        </>,
        <>
            <span className="lightBlueText">UI Copy for Websites and Apps</span>
            <span>We have a strong expertise in creating clear and concise copy for buttons, labels, form fields, error messages, tooltips, and other UI elements, ensuring that they are intuitive, informative, and easy to understand for users.
            <br/>
             Well-crafted copy makes user interfaces more accessible while building trust with the brand.</span>
        </>,
        <>
            <span className="lightBlueText">UX Content Strategy</span>
            <span>In developing a content strategy for the product, we make certain that the UX copy is standardized, in line with the brand's voice and tone, and optimized for user comprehension and engagement.</span>
        </>,
    ],
}

export const socialLinks = {
    facebook: 'https://www.facebook.com/profile.php?id=100088206934224&mibextid=LQQJ4d',
    instagram: 'https://www.instagram.com/khgtec/',
    linkedIn: 'https://www.linkedin.com/company/khgtec/',
    behance: 'https://www.behance.net/khgtec',
    whatsapp: 'https://wa.me/+37494069650',
    upwork: 'https://www.upwork.com/agencies/1623267160927113216/',
}

export const contactLinks = {
    phone: 'tel:+37494069650',
    map: 'https://www.google.com/maps/place/16+Aram+Khachatrian+St,+Yerevan+0012,+%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D1%8F/@40.205076,44.4960482,17z/data=!3m1!4b1!4m5!3m4!1s0x406abd43f921f277:0x4d08ce508918d0d3!8m2!3d40.2050719!4d44.4982369',
    contactMail: 'mailto:contact@khgtec.com',
    infoMail: 'mailto:info@khgtec.com',
}
