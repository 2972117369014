import React, {lazy, Suspense} from 'react';
import CareerHeader from "../components/CareerPage/CareerHeader/CareerHeader";
import Head from '../components/global/Head/Head'

const CareerDescription = lazy(() => import("../components/CareerPage/CareerDescription/CareerDescription"));
const CareerPagePositionList = lazy(() => import("../components/CareerPage/CareerPagePositionList/CareerPagePositionList"));

function CareerPage() {
    return (
        <>
            <Head
                keyword={'IT careers, IT jobs, IT vacancies, IT startup, technology careers, job opportunities, job openings, open positions, career growth, professional development'}
                ogKeyword={'IT careers, IT jobs, IT vacancies, IT startup, technology careers, job opportunities, job openings, open positions, career growth, professional development'}

                title={'Careers - Web and Mobile Development Jobs | KHG tec'}
                ogTitle={'Careers - Web and Mobile Development Jobs | KHG tec'}

                description={'Explore the career opportunities available at KHG tec and join our team of experts. We provide cutting-edge projects and continuous growth opportunities.'}
                ogDescription={'Explore the career opportunities available at KHG tec and join our team of experts. We provide cutting-edge projects and continuous growth opportunities.'}
            />

            <CareerHeader/>
            <Suspense fallback={null}>
                <CareerDescription/>
            </Suspense>
            <Suspense fallback={null}>
                <CareerPagePositionList/>
            </Suspense>
        </>
    );
}

export default CareerPage;