import React from 'react';
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

import styles from "./SeoHeader.module.scss"
import {seoHeaderImg, seoHeaderImgWebp} from "../../../assets/images";

function SeoHeader() {
    return (
        <header className={styles['seoHeader']}>
            <div className={`${styles["seoHeader__container"]} container`}>
                <h2 className={`${styles['seoHeader__title']} headerTitle`}>Improve<span className="lightBlueText"> Google Search </span>
                    Rankings</h2>
                <div className={`${styles["seoHeader__imgBlock"]} lightBlueBorderBox`}>
                    <ImageWebp
                        pictureClass={styles["seoHeader__picture"]}
                        srcSet={seoHeaderImgWebp}
                        src={seoHeaderImg}
                        alt="Ui UX design"
                        className={styles["seoHeader__img"]}
                    />
                </div>
            </div>
        </header>
    );
}

export default SeoHeader;