import {
    CLEAR_SUBSCRIBE_ERROR,
    CLEAR_SUBSCRIBE_MESSAGE,
    SUBSCRIBE_FAIL,
    SUBSCRIBE_LOADING_START,
    SUBSCRIBE_SUCCESS
} from "../types";


const initialState = {
    subscribeMessage: false,
    loading: false,
    error: null
}

export const subscribeReducer = (state = initialState,action) => {
    const {type,payload} = action
    switch (type) {
        case SUBSCRIBE_SUCCESS:
            return {
                ...state,
                subscribeMessage: true,
                loading: false,
            }
        case SUBSCRIBE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case SUBSCRIBE_LOADING_START:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_SUBSCRIBE_MESSAGE:
            return {
                ...state,
                subscribeMessage: false,
            }
        case CLEAR_SUBSCRIBE_ERROR:
            return {
                ...state,
                error: null,
            }
        default:
            return state
    }
}