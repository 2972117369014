import React, {lazy, Suspense} from 'react';
import AppDevelopmentHeader from "../components/AppDevelopmentPage/AppDevelopmentHeader/AppDevelopmentHeader";
import {appDevelopmentAboutMobileTexts, appTechnologiesIconImagesArr} from "../assets/UIData";

import Head from '../components/global/Head/Head'

const AppDevelopmentDesktopApps = lazy(() => import("../components/AppDevelopmentPage/AppDevelopmentDesktopApps/AppDevelopmentDesktopApps"));
const WrapperWithImg = lazy(() => import("../components/layout/WrapperWithImg/WrapperWithImg"));
const TechnologiesBlock = lazy(() => import("../components/global/TechnologiesBlock/TechnologiesBlock"));
const BlogSlider = lazy(() => import("../components/global/BlogSlider/BlogSlider"));

function AppDevelopmentPage() {
    return (
        <>
            <Head
                keyword={'app design, app development services, mobile app development, desktop app development, multi-platform app development, custom app development, iOS app development, Android app development, cross-platform app development, mobile UX/UI design, app maintenance and support, mobile app SEO, mobile usability'}
                ogKeyword={'app design, app development services, mobile app development, desktop app development, multi-platform app development, custom app development, iOS app development, Android app development, cross-platform app development, mobile UX/UI design, app maintenance and support, mobile app SEO, mobile usability'}

                title={'Mobile and Desktop App Development | Mobile App SEO | KHG tec'}
                ogTitle={'Mobile and Desktop App Development | Mobile App SEO | KHG tec'}

                description={'Our services include mobile app development for iOS and Android platforms, cross-platform solutions for desktop apps, UX/UI design, support, and more.'}
                ogDescription={'Our services include mobile app development for iOS and Android platforms, cross-platform solutions for desktop apps, UX/UI design, support, and more.'}
            />

            <AppDevelopmentHeader/>
            <Suspense fallback={null}>
                <AppDevelopmentDesktopApps/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...appDevelopmentAboutMobileTexts}/>
            </Suspense>
            <Suspense fallback={null}>
                <TechnologiesBlock iconImagesArr={appTechnologiesIconImagesArr}/>
            </Suspense>
            <Suspense fallback={null}>
                <BlogSlider type={'app'}/>
            </Suspense>
        </>
    );
}

export default AppDevelopmentPage;