import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from  './AboutHeader.module.scss';
import {logoWall, logoWallWebp} from "../../../assets/images";

function AboutHeader () {
     return (
         <section className={styles['aboutHeader']}>
             <ImageWebp className={styles['aboutHeader__imgLogoWall']} src={logoWall} srcSet={logoWallWebp} alt='KHG Tec'/>
         </section>
     )
}

export default AboutHeader