import React, {useEffect, useState} from 'react';

import styles from "./AppDevelopmentHeader.module.scss"
import {
    appDevelopmentHeader,
    appDevelopmentHeaderBig,
    appDevelopmentHeaderBigMob, appDevelopmentHeaderBigMobBlur,
    appDevelopmentHeaderBigMobWebp, appDevelopmentHeaderBigMobWebpBlur,
    appDevelopmentHeaderBigWebp,
    appDevelopmentHeaderSmMob, appDevelopmentHeaderSmMobBlur,
    appDevelopmentHeaderSmMobWebp, appDevelopmentHeaderSmMobWebpBlur,
    appDevelopmentHeaderTab, appDevelopmentHeaderTabBlur,
    appDevelopmentHeaderTabWebp,
    appDevelopmentHeaderTabWebpBlur,
    appDevelopmentHeaderWebp
} from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

function AppDevelopmentHeader() {
    const [imgLoaded, setImgLoaded] = useState(false)
    const [windowSize, setWindowSize] = useState('')

    useEffect(() => {
        changeWindowSize()
        window.addEventListener('resize', changeWindowSize)

        return () => {
            window.removeEventListener('resize', changeWindowSize)
        }
    },[])

    const changeWindowSize = () => {
        if (window.innerWidth <= 768 && window.innerWidth >= 576) {
            setWindowSize('tab')
        } else if (window.innerWidth <= 576 && window.innerWidth >= 390) {
            setWindowSize('bigMob')
        } else if (window.innerWidth <= 390) {
            setWindowSize('smMob')
        } else {
            setWindowSize('desk')
        }
    }

    const onLoadImg = () => setImgLoaded(true)

    return (
        <header className={`${styles['appDevelopmentHeader']} ${imgLoaded ? styles['appDevelopmentHeader_loaded'] : ''}`}>
            {windowSize === 'desk' &&
                <>
                    <ImageWebp
                        srcSet={appDevelopmentHeaderBigWebp}
                        src={appDevelopmentHeaderBig}
                        alt="App development"
                        onLoad={onLoadImg}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["appDevelopmentHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={appDevelopmentHeaderWebp}
                        src={appDevelopmentHeader}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["appDevelopmentHeader__img"]} ${styles["appDevelopmentHeader__img_blur"]}`}
                    />
                </>
            }
            {windowSize === 'tab' &&
                <>
                    <ImageWebp
                        srcSet={appDevelopmentHeaderTabWebp}
                        src={appDevelopmentHeaderTab}
                        alt="App development"
                        onLoad={onLoadImg}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["appDevelopmentHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={appDevelopmentHeaderTabWebpBlur}
                        src={appDevelopmentHeaderTabBlur}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["appDevelopmentHeader__img"]} ${styles["appDevelopmentHeader__img_blur"]}`}
                    />
                </>
            }
            {windowSize === 'bigMob' &&
                <>
                    <ImageWebp
                        srcSet={appDevelopmentHeaderBigMobWebp}
                        src={appDevelopmentHeaderBigMob}
                        alt="App development"
                        onLoad={onLoadImg}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["appDevelopmentHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={appDevelopmentHeaderBigMobWebpBlur}
                        src={appDevelopmentHeaderBigMobBlur}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["appDevelopmentHeader__img"]} ${styles["appDevelopmentHeader__img_blur"]}`}
                    />
                </>
            }
            {windowSize === 'smMob' &&
                <>
                    <ImageWebp
                        srcSet={appDevelopmentHeaderSmMobWebp}
                        src={appDevelopmentHeaderSmMob}
                        alt="App development"
                        onLoad={onLoadImg}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["appDevelopmentHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={appDevelopmentHeaderSmMobWebpBlur}
                        src={appDevelopmentHeaderSmMobBlur}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["appDevelopmentHeader__img"]} ${styles["appDevelopmentHeader__img_blur"]}`}
                    />
                </>
            }
            <div className={styles["appDevelopmentHeader__textBlock"]}>
                <div className={`${styles["appDevelopmentHeader__textBlockContainer"]} ${imgLoaded ? styles["appDevelopmentHeader__textBlockContainer_loaded"] : ''} container`}>
                    <h2 className={`${styles["appDevelopmentHeader__title"]} headerTitle`}>Let’s create and level up
                        your <span className="lightBlueText">app</span> together</h2>
                    <p className={`${styles["appDevelopmentHeader__contentText"]} contentTextDef`}>Build custom applications that meet your unique business requirements with our experienced engineers in the short term.
                   </p>
                    <p className={`${styles["appDevelopmentHeader__contentText"]} contentTextDef`}>
                        We focus on the user experience design, cutting-edge technology and design to create dynamic applications.
                    </p>
                </div>
            </div>
        </header>
    );
}

export default AppDevelopmentHeader;