import React, { useState} from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Svg from "../../layout/Svg/Svg";

import styles from "./IndBlogHeader.module.scss"
import {instagramBlueIcon,facebookIcon,linkedInIcon} from "../../../assets/svg";
import {particleOptions, socialLinks} from "../../../assets/UIData";
import IndBlogUserBlock from "../IndBlogUserBlock/IndBlogUserBlock";
import {baseUrl} from "../../../redux/action/fetchTools";
import {loadingLottie} from "../../../assets/lotties";
import Lottie from "lottie-react";
function IndBlogHeader({title,image}) {
    const [imgLoaded,setImgLoaded] = useState(false)

    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const onLoadImg = () => setImgLoaded(true)
    
    return (
        <header className={styles['indBlogHeader']}>
            <div className={styles["indBlogHeader__bg"]} >
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    style={{position: 'static'}}
                    options={particleOptions}
                    className={styles["indBlogHeader__particle"]}
                />
            </div>
            <div className={`${styles["indBlogHeader__container"]} container`}>
                <div className={styles["indBlogHeader__topBlock"]}>
                    <div className={styles["indBlogHeader__socialBlock"]}>
                        <h1 className={`${styles["indBlogHeader__text"]} titleDef`}>{title}</h1>
                        <div className={styles["indBlogHeader__socialIconsBlock"]}>
                            <a href={socialLinks.facebook} target='_blank' rel="noreferrer"><Svg className={styles['indBlogHeader__socialIcon']} id={facebookIcon}/></a>
                            <a href={socialLinks.instagram} target='_blank' rel="noreferrer"><Svg className={styles['indBlogHeader__socialIcon']} id={instagramBlueIcon}/></a>
                            <a href={socialLinks.linkedIn} target='_blank' rel="noreferrer"><Svg className={styles['indBlogHeader__socialIcon']} id={linkedInIcon}/></a>
                        </div>
                    </div>
                    <IndBlogUserBlock className={styles["indBlogHeader__userBlock"]}/>
                </div>
                <div className={styles["indBlogHeader__mainImgBlock"]}>
                    <img
                        src={image ? baseUrl + image : ''}
                        alt="main blog"
                        className={styles["indBlogHeader__mainImg"]}
                        onLoad={onLoadImg}
                        style={{display: imgLoaded && image ? 'block' : 'none'}}
                    />
                    {!imgLoaded || !image ? <Lottie
                        animationData={loadingLottie}
                        loop={true}
                        className={styles["indBlogHeader__loader"]}
                    /> : ''}
                </div>
            </div>
        </header>
    );
}

export default IndBlogHeader;