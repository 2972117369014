import styles from "./WebDevelopmentHeader.module.scss"
import {
    webDevCpu, webDevCpuWebp, webDevHeaderCPUIcon,
    webDevHeaderLaptopImg,
    webDevHeaderMainImg, webDevHeaderMainImgWebp, webDevHeaderSensorIcon,
    webDevSensorArrowImg,
    webDevSensorCenter
} from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

function WebDevelopmentHeader({onLoadHeaderImage}) {
    return (
        <header className={styles['webDevelopmentHeader']}>
            <div className={`${styles["webDevelopmentHeader__container"]} container`}>
                <h2 className={`${styles["webDevelopmentHeader__title"]} headerTitle`}>Get a custom Website optimized for<span className="lightBlueText"> SEO</span> and<span className="lightBlueText"> mobile</span></h2>
                <div className={styles["webDevelopmentHeader__main"]}>
                    <div className={styles["webDevelopmentHeader__mainImgBlock"]}>
                        <ImageWebp
                            srcSet={webDevHeaderMainImgWebp}
                            src={webDevHeaderMainImg}
                            alt="web development"
                             className={styles["webDevelopmentHeader__mainImg"]}
                            onLoad={onLoadHeaderImage}
                        />
                        <div className={styles["webDevelopmentHeader__sensorImgBlock"]}>
                            <div className={styles["webDevelopmentHeader__sensorArrow"]}>
                                <img className={styles["webDevelopmentHeader__sensorArrowImg"]}
                                     src={webDevSensorArrowImg} alt="sensor arrow"/>
                                <img className={styles["webDevelopmentHeader__sensorCenterImg"]}
                                     src={webDevSensorCenter} alt="sensor arrow"/>
                            </div>
                                <p className={styles["webDevelopmentHeader__mbpsText"]}></p>
                        </div>
                        <div className={styles["webDevelopmentHeader__progressBlock"]}>
                            <img src={webDevHeaderLaptopImg} alt="laptop" className={styles["webDevelopmentHeader__laptopImg"]}/>
                            <div className={styles["webDevelopmentHeader__progressBars"]}>
                                <div className={styles["webDevelopmentHeader__progressbarBlock"]}>
                                    <p className={styles["webDevelopmentHeader__progressbarLabel"]}>
                                        <img src={webDevHeaderSensorIcon} className={styles["webDevelopmentHeader__progressbarLabelImg"]} alt="sensor"/>
                                        Unlimited Power
                                    </p>
                                    <div className={styles["webDevelopmentHeader__progressbar"]}>
                                        <div className={`${styles["webDevelopmentHeader__progressbarInner"]} ${styles["webDevelopmentHeader__progressbarInner_progressUp"]}`}></div>
                                    </div>
                                </div>
                                <div className={styles["webDevelopmentHeader__progressbarBlock"]}>
                                    <p className={styles["webDevelopmentHeader__progressbarLabel"]}>
                                        <img src={webDevHeaderCPUIcon} className={styles["webDevelopmentHeader__progressbarLabelImg"]} alt="CPU"/>
                                        Less CPU Usage
                                    </p>
                                    <div className={styles["webDevelopmentHeader__progressbar"]}>
                                        <div className={`${styles["webDevelopmentHeader__progressbarInner"]} ${styles["webDevelopmentHeader__progressbarInner_progressDown"]}`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["webDevelopmentHeader__cpuBlock"]}>
                        <ImageWebp srcSet={webDevCpuWebp} src={webDevCpu} alt="CPU" className={styles["webDevelopmentHeader__cpuImg"]}/>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default WebDevelopmentHeader;