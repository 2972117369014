import AboutHeader from '../components/About/AboutHeader/AboutHeader';

import {aboutWrapperWithImg1, aboutWrapperWithImg2} from "../assets/UIData";
import {lazy, Suspense} from "react";
import Head from '../components/global/Head/Head'

const AboutProduct = lazy(() => import('../components/About/AboutProduct/AboutProduct'));
const AboutStaffSlider = lazy(() => import('../components/About/AboutStaffSlider/AboutStaffSlider'));
const WrapperWithImg = lazy(() => import("../components/layout/WrapperWithImg/WrapperWithImg"));

function AboutPage() {
    return (
        <>

            <Head
                keyword={'web and mobile development company, full-cycle development, website development, web development,mobile development, scrum masters, Agile Scrum for web development, Agile software development, quality control and testing, website security, DevOps services, website launch and support'}
                ogKeyword={'web and mobile development company, full-cycle development, website development, web development,mobile development, scrum masters, Agile Scrum for web development, Agile software development, quality control and testing, website security, DevOps services, website launch and support'}

                title={'Full-service web and mobile app development company | KHG tec'}
                ogTitle={'Full-service web and mobile app development company | KHG tec'}

                description={'As a beginning-to-end software development provider, we follow Agile Methodology to deliver high-quality, secure websites and apps.'}
                ogDescription={'As a beginning-to-end software development provider, we follow Agile Methodology to deliver high-quality, secure websites and apps.'}
            />

            <AboutHeader/>
            <Suspense fallback={null}>
                <AboutProduct/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...aboutWrapperWithImg1} />
            </Suspense>
            <Suspense fallback={null}>
                <AboutStaffSlider/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...aboutWrapperWithImg2}/>
            </Suspense>
        </>
    );
}

export default AboutPage;