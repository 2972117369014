import React, {useCallback, useEffect, useRef, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";

import {
    designPagePath,
    webDevelopmentPath,
    appDevelopmentPath,
    seoPagePath,
} from "../../../../router/path";

import styles from "./NavbarServicesDropdown.module.scss"
import {navbarAppDevImg, navbarDesignImg, navbarSeoImg, navbarWebDevImg} from "../../../../assets/images"
import Backdrop from "../../../layout/Backdrop/Backdrop";
import {scrollTop} from "../../../../globalFunctions";

function NavbarServicesDropdown({closeServicesDropdown, servicesDropdownOpened, dropBtnRef}) {
    const {pathname} = useLocation()
    const dropdownRef = useRef(null)
    const [activeImage, setActiveImage] = useState(designPagePath)
    const setActiveImageInit = useCallback(() => {
            if (
                pathname === designPagePath ||
                pathname === webDevelopmentPath ||
                pathname === appDevelopmentPath ||
                pathname === seoPagePath
            ) {
                setActiveImage(pathname)
            }
        },[pathname])

    useEffect(() => {
        setActiveImageInit()
    // eslint disable next line react-hooks/exhaustive-deps
    }, [pathname,setActiveImageInit])



    const onHover = (e) => {
        setActiveImage(e.target.href)
    }

    const onMouseOut = () => {
        setActiveImageInit()
    }

    const onClickLink = () => {
        closeServicesDropdown()
        scrollTop()
    }

    return (
        <>
            <Backdrop
                enableScroll={true}
                inProp={servicesDropdownOpened}
                onClose={closeServicesDropdown}
            />
            <div className={styles['navbarServicesDropdown']} ref={dropdownRef}>
                <div className={styles['navbarServicesDropdown__container']}>
                    <nav className={styles["navbarServicesDropdown__menu"]} onMouseLeave={onMouseOut}>
                        <NavLink
                            onClick={onClickLink}
                            onMouseOver={onHover}
                            to={designPagePath}
                            className={`${activeImage.includes(designPagePath) ? styles['navbarServicesDropdown__link_active'] : ''} ${styles['navbarServicesDropdown__link']}`}>UX/UI
                            Design</NavLink>
                        <NavLink
                            onClick={onClickLink}
                            onMouseOver={onHover}
                            to={webDevelopmentPath}
                            className={`${activeImage.includes(webDevelopmentPath) ? styles['navbarServicesDropdown__link_active'] : ''} ${styles['navbarServicesDropdown__link']}`}>Web
                            Development</NavLink>
                        <NavLink
                            onClick={onClickLink}
                            onMouseOver={onHover}
                            to={appDevelopmentPath}
                            className={`${activeImage.includes(appDevelopmentPath) ? styles['navbarServicesDropdown__link_active'] : ''} ${styles['navbarServicesDropdown__link']}`}>App
                            Development</NavLink>
                        <NavLink
                            onClick={onClickLink}
                            onMouseOver={onHover}
                            to={seoPagePath}
                            className={`${activeImage.includes(seoPagePath) ? styles['navbarServicesDropdown__link_active'] : ''} ${styles['navbarServicesDropdown__link']}`}>SEO
                            & UX
                            Writing</NavLink>
                    </nav>
                    <div className={styles["navbarServicesDropdown__imageBlock"]}>
                        <img
                            src={navbarDesignImg}
                            alt="ui/ux"
                            className={styles["navbarServicesDropdown__img"]}
                            style={{opacity: activeImage.includes(designPagePath) ? '1' : '0'}}
                        />
                        <img
                            src={navbarWebDevImg}
                            alt="web development"
                            className={styles["navbarServicesDropdown__img"]}
                            style={{opacity: activeImage.includes(webDevelopmentPath) ? '1' : '0'}}
                        />
                        <img
                            src={navbarAppDevImg}
                            alt="app development"
                            className={styles["navbarServicesDropdown__img"]}
                            style={{opacity: activeImage.includes(appDevelopmentPath) ? '1' : '0'}}
                        />
                        <img
                            src={navbarSeoImg}
                            alt="seo"
                            className={styles["navbarServicesDropdown__img"]}
                            style={{opacity: activeImage.includes(seoPagePath) ? '1' : '0'}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavbarServicesDropdown;