import React, {lazy, Suspense} from 'react';

const ErrorBlock = lazy(() => import("../components/ErrorPage/ErrorBlock/ErrorBlock"));


function ErrorPage() {
    return (
        <Suspense fallback={null}>
            <ErrorBlock/>
        </Suspense>
    );
}

export default ErrorPage;