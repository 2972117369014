import React, {lazy, Suspense} from "react";
import Head from "../components/global/Head/Head"

const PolicyText = lazy(() => import("../components/PolicyTermsTexts/PolicyText"));

function PrivacyPage() {
    return (
        <>
            <Suspense fallback={null}>
                <PolicyText/>
            </Suspense>

            <Head
                keyword={'website privacy policy, Privacy Policies for mobile apps, privacy rights, privacy settings, data privacy, data security, cookies policy, cookies consent, children\'s privacy, user privacy, user consent, sensitive information, confidentiality, personal safety, personal information protection, data protection, information security, third-party disclosure, security measures, unsubscribing, controlling personal information, security and access'}
                ogKeyword={'website privacy policy, Privacy Policies for mobile apps, privacy rights, privacy settings, data privacy, data security, cookies policy, cookies consent, children\'s privacy, user privacy, user consent, sensitive information, confidentiality, personal safety, personal information protection, data protection, information security, third-party disclosure, security measures, unsubscribing, controlling personal information, security and access'}

                title={'Privacy Policy for Websites and Mobile Apps | KHG tec - add to our Privacy Policy page'}
                ogTitle={'Privacy Policy for Websites and Mobile Apps | KHG tec - add to our Privacy Policy page'}

                description={'We uphold the highest standards of confidentiality, implementing robust measures to ensure your personal safety and information security. '}
                ogDescription={'We uphold the highest standards of confidentiality, implementing robust measures to ensure your personal safety and information security. '}
            />
        </>
    );
}

export default PrivacyPage;