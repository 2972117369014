//UI
export const TOGGLE_SUBSCRIBE_POPUP = 'TOGGLE_SUBSCRIBE_POPUP'
export const SET_BLOG_HEADER_IMAGE_HEIGHT = 'SET_BLOG_HEADER_IMAGE_HEIGHT'
export const SET_DESIGN_HEADER_IMAGE_HEIGHT = 'SET_DESIGN_HEADER_IMAGE_HEIGHT'

//mainBlog
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS'
export const GET_ALL_BLOGS_FAIL = 'GET_ALL_BLOGS_FAIL'
export const GET_ALL_BLOGS_LOADING_START = 'GET_ALL_BLOGS_LOADING_START'
export const SET_BLOG_SCROLL_POSITION = 'SET_BLOG_SCROLL_POSITION'

//indBlog
export const GET_IND_BLOG_SUCCESS = 'GET_IND_BLOG_SUCCESS'
export const GET_IND_BLOG_FAIL = 'GET_IND_BLOG_FAIL'
export const GET_IND_BLOG_LOADING_START = 'GET_IND_BLOG_LOADING_START'
export const CLEAR_IND_BLOG_DATA = 'CLEAR_IND_BLOG_DATA'

//subscribe
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAIL = 'SUBSCRIBE_FAIL'
export const SUBSCRIBE_LOADING_START = 'SUBSCRIBE_LOADING_START'
export const CLEAR_SUBSCRIBE_MESSAGE = 'CLEAR_SUBSCRIBE_MESSAGE'
export const CLEAR_SUBSCRIBE_ERROR = 'CLEAR_SUBSCRIBE_ERROR'

//career
export const GET_CAREER_SUCCESS = 'GET_CAREER_SUCCESS'
export const GET_CAREER_FAIL = 'GET_CAREER_FAIL'
export const GET_CAREER_LOADING_START = 'GET_CAREER_LOADING_START'
export const SET_SELECTED_PROFESSION = 'SET_SELECTED_PROFESSION'
export const CLEAR_SELECTED_PROFESSION = 'CLEAR_SELECTED_PROFESSION'

//join
//subscribe
export const JOIN_SUCCESS = 'JOIN_SUCCESS'
export const JOIN_FAIL = 'JOIN_FAIL'
export const JOIN_LOADING_START = 'JOIN_LOADING_START'
export const CLEAR_JOIN_MESSAGE = 'CLEAR_JOIN_MESSAGE'
export const CLEAR_JOIN_ERROR = 'CLEAR_JOIN_ERROR'

// contact
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_FAIL = 'CONTACT_FAIL'
export const CONTACT_LOADING_START = 'CONTACT_LOADING_START'
export const CLEAR_CONTACT_MESSAGE = 'CLEAR_CONTACT_MESSAGE'
export const CLEAR_CONTACT_ERROR = 'CLEAR_CONTACT_ERROR'
