import React, {lazy, Suspense, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearIndBlogData, getIndBlog} from "../../../redux/action/indBlog";

import IndBlogHeader from "../IndBlogHeader/IndBlogHeader";

const IndBlogDescription = lazy(() => import("../IndBlogDescription/IndBlogDescription"));
const IndBlogSlider = lazy(() => import("../IndBlogSlider/IndBlogSlider"));

function IndBlogWrapper() {
    const dispatch = useDispatch()
    const scrollPosition = useSelector(state => state.mainBlog.scrollPosition)
    const navigate = useNavigate()
    const {blogId} = useParams()
    const {data} = useSelector(state => state.indBlog)
    const params = useParams()


    useEffect(() => {
        let isbacked = false;
        window.addEventListener('popstate', function (e) {
            e.preventDefault()

            setTimeout(() => {

            if (scrollPosition) {
                window.scrollTo({
                    top: scrollPosition,
                    left: 0,
                    behavior: 'instant'
                });
            }
            },0)
            if(!isbacked) navigate(-1)
            isbacked = true;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(getIndBlog(blogId))

        return () => {
            dispatch(clearIndBlogData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.blogId])

    useEffect(() => {
        if (data?.title && !params?.title) navigate(data.title.toLowerCase().replaceAll(/[\s/]/g, '-'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <>
            <Helmet>
                <meta name="description" content={data?.description}/>
                <meta name="title" content={data?.title}/>
                <meta name="keywords" content={data?.meta_keywords?.join(',')}/>
                <title>KHG tec {data?.title ? ' | ' + data?.title : ''}</title>
            </Helmet>
            <IndBlogHeader
                title={data?.title}
                image={data?.image}
            />
            <Suspense fallback={null}>
                <IndBlogDescription content={data?.content}/>
            </Suspense>
            <Suspense fallback={null}>
                <IndBlogSlider type={data?.type}/>
            </Suspense>
        </>
    );
}

export default IndBlogWrapper;
