import {lazy, Suspense, useEffect} from "react";
import {Outlet, Route, Routes} from 'react-router-dom';
import Head from '../global/Head/Head'

import Navbar from "../global/Navbar/Navbar";
import HomePage from "../../pages/HomePage";
import AboutPage from "../../pages/AboutPage";
import ContactPage from "../../pages/ContactPage";
import BlogPage from "../../pages/BlogPage";
import UiUxDesignPage from "../../pages/UiUxDesignPage";
import WebDevelopmentPage from "../../pages/WebDevelopmentPage";
import AppDevelopmentPage from "../../pages/AppDevelopmentPage";
import SeoPage from "../../pages/SeoPage";
import FAQPage from "../../pages/FAQPage";
import IndBlogPage from "../../pages/IndBlogPage";
import ErrorPage from "../../pages/ErrorPage";
import PrivacyPage from "../../pages/PrivacyPage";
import TermsPage from "../../pages/TermsPage";
import CareerPage from "../../pages/CareerPage";
import JoinPage from "../../pages/JoinPage";
import ServerErrorPage from "../../pages/ServerErrorPage";

import {
    aboutPagePath,
    blobPagePath,
    homePagePath,
    contactPagePath,
    designPagePath,
    webDevelopmentPath,
    appDevelopmentPath,
    seoPagePath,
    privacyPagePath,
    termsPagePath,
    careerPagePath,
    serverErrorPagePath,
    faqPagePath

} from "../../router/path";

const Footer = lazy(() => import("../global/Footer/Footer"));

function App() {

    useEffect(() => {
        localStorage.removeItem('designAnimCompleted')
    }, [])
    return (
        <>
            <Head
                keyword={'web technologies, web development, app development, mobile app development, mobile applications, desktop applications, custom-built websites, custom web development, website design, UX/UI Design, creativity, innovative solutions, secure mobile apps, secure websites, strategic optimization, SEO Ranking, SEO optimization, UX Writing'}
                ogKeyword={'web technologies, web development, app development, mobile app development, mobile applications, desktop applications, custom-built websites, custom web development, website design, UX/UI Design, creativity, innovative solutions, secure mobile apps, secure websites, strategic optimization, SEO Ranking, SEO optimization, UX Writing'}

                title={'Custom-built Websites and Mobile/Desktop Applications | KHG tec'}
                ogTitle={'Custom-built Websites and Mobile/Desktop Applications | KHG tec'}

                description={'We provide convenient UX/UI design, secure software development, effective SEO strategies, reliable technical support, and more. '}
                ogDescription={'We provide convenient UX/UI design, secure software development, effective SEO strategies, reliable technical support, and more. '}
            />
            <Navbar/>
            <Routes>
                <Route path={homePagePath} element={<HomePage/>}/>
                <Route path={aboutPagePath} element={<AboutPage/>}/>
                <Route path={contactPagePath} element={<ContactPage/>}/>
                <Route path={blobPagePath} element={<Outlet/>}>
                    <Route index element={<BlogPage/>}/>
                    <Route path=':blogId' element={<Outlet/>}>
                        <Route index element={<IndBlogPage/>}/>
                        <Route path=':title' element={<IndBlogPage/>}/>
                    </Route>
                </Route>
                <Route path={designPagePath} element={<UiUxDesignPage/>}/>
                <Route path={webDevelopmentPath} element={<WebDevelopmentPage/>}/>
                <Route path={appDevelopmentPath} element={<AppDevelopmentPage/>}/>
                <Route path={seoPagePath} element={<SeoPage/>}/>
                <Route path={privacyPagePath} element={<PrivacyPage/>}/>
                <Route path={termsPagePath} element={<TermsPage/>}/>
                <Route path={faqPagePath} element={<FAQPage/>}/>
                <Route path={careerPagePath} element={<Outlet/>}>
                    <Route index element={<CareerPage/>}/>
                    <Route path=':professionId' element={<JoinPage/>}/>
                </Route>
                <Route path={'*'} element={<ErrorPage/>}/>
                <Route path={serverErrorPagePath} element={<ServerErrorPage/>}/>
            </Routes>
            <Suspense fallback={null}>
                <Footer/>
            </Suspense>
        </>
    );
}

export default App;
