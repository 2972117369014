import {SET_BLOG_HEADER_IMAGE_HEIGHT, SET_DESIGN_HEADER_IMAGE_HEIGHT, TOGGLE_SUBSCRIBE_POPUP} from "../types";


const initialState = {
    subscribePopupOpened: false,
    blogHeaderImageHeight: 0,
    designHeaderImageHeight: 0,
}

export const UIReducer = (state = initialState,action) => {
    const {type,payload} = action

    switch (type) {
        case TOGGLE_SUBSCRIBE_POPUP:
            return {
                ...state,
                subscribePopupOpened: payload
            }
        case SET_BLOG_HEADER_IMAGE_HEIGHT:
            return {
                ...state,
                blogHeaderImageHeight: payload
            }
        case SET_DESIGN_HEADER_IMAGE_HEIGHT:
            return {
                ...state,
                designHeaderImageHeight: payload
            }
        default:
            return state
    }
}
