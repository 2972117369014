import React, {Suspense, useEffect, lazy} from 'react';
import {useNavigate} from "react-router-dom"

import HomeHeader from "../components/HomePage/HomeHeader/HomeHeader";
import {
    homeWebDevText,
    homeAppDevText,
    homeSeoText
} from "../assets/UIData";
import {appDevelopmentPath, seoPagePath, webDevelopmentPath} from "../router/path";
import {toggleSubscribePopup} from "../redux/action/UI";
import {useDispatch} from "react-redux";
import {scrollTop} from "../globalFunctions";

const HomeAdvertisement = lazy(() => import('../components/HomePage/HomeAdvertisement/HomeAdvertisement'))
const WrapperWithImg = lazy(() => import("../components/layout/WrapperWithImg/WrapperWithImg"));
const HomeAboutDesign = lazy(() => import("../components/HomePage/HomeAboutDesign/HomeAboutDesign"));
const SubscribePopup = lazy(() => import("../components/global/SubscribePopup/SubscribePopup"));
const BlogSlider = lazy(() => import("../components/global/BlogSlider/BlogSlider"));

function HomePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        let timeOut;
        if (!sessionStorage.getItem("subscribePopupShowed")) {
            timeOut = setTimeout(() => {
                dispatch(toggleSubscribePopup(true))
                sessionStorage.setItem("subscribePopupShowed", "true")
            }, 7000)
        }
        return () => {
            if (timeOut) clearTimeout(timeOut)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickWebDevBtn = () => {
        navigate(webDevelopmentPath)
        scrollTop()
    }
    const onClickAppDevBtn = () => {
        navigate(appDevelopmentPath)
        scrollTop()
    }
    const onClickSeoBtn = () => {
        navigate(seoPagePath)
        scrollTop()
    }

    return (
        <>
            <HomeHeader/>
            <Suspense fallback={null}>
                <HomeAdvertisement/>
            </Suspense>
            <Suspense fallback={null}>
                <HomeAboutDesign/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...homeWebDevText} onClick={onClickWebDevBtn}/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...homeAppDevText} onClick={onClickAppDevBtn}/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...homeSeoText} onClick={onClickSeoBtn}/>
            </Suspense>
            <Suspense fallback={null}>
                <SubscribePopup/>
            </Suspense>
            <Suspense fallback={null}>
                <BlogSlider smallDistance={true} type={'all'}/>
            </Suspense>
        </>
    );
}

export default HomePage;