import {combineReducers} from "redux"
import {UIReducer} from "./UIReducer";
import {mainBlogReducer} from "./mainBlogReducer";
import {indBlogReducer} from "./indBlogReducer";
import {subscribeReducer} from "./subscribeReduces";
import {careerReducer} from "./careerReducer";
import {joinReducer} from "./joinReducer";
import {contactReducer} from "./contactReducer";


export default combineReducers({
    UI: UIReducer,
    mainBlog: mainBlogReducer,
    indBlog: indBlogReducer,
    subscribe: subscribeReducer,
    career: careerReducer,
    join: joinReducer,
    contact: contactReducer
})