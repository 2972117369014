import {
    CLEAR_SELECTED_PROFESSION,
    GET_CAREER_FAIL,
    GET_CAREER_LOADING_START,
    GET_CAREER_SUCCESS,
    SET_SELECTED_PROFESSION
} from "../types";


const initialState = {
    data: null,
    loading: false,
    error: null,
    selectedProfession: null,
}

export const careerReducer = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case GET_CAREER_SUCCESS :
            return {
                ...state,
                data: payload,
                loading: false,
            }
        case GET_CAREER_FAIL :
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case GET_CAREER_LOADING_START :
            return {
                ...state,
                loading: true,
            }
        case SET_SELECTED_PROFESSION :
            return {
                ...state,
                selectedProfession: payload,
            }
        case CLEAR_SELECTED_PROFESSION :
            return {
                ...state,
                selectedProfession: null,
            }
        default:
            return state
    }
}