import React, {lazy, Suspense} from 'react';
import UiUxDesignHeader from "../components/UiUxDesignPage/UiUxDesignHeader/UiUxDesignHeader";
import Head from '../components/global/Head/Head'

import {designResponsiveText, designText} from "../assets/UIData";

const UiUxDesignExamples = lazy(() => import("../components/UiUxDesignPage/UiUxDesignExamples/UiUxDesignExamples"))
const WrapperWithImg = lazy(() => import("../components/layout/WrapperWithImg/WrapperWithImg"))
const BlogSlider = lazy(() => import("../components/global/BlogSlider/BlogSlider"))

function UiUxDesignPage() {
    return (
        <>
            <Head
                keyword={'user interface design, user experience design, user-centered design, intuitive interface, UX/UI design services, mobile app UX/UI design, visual design, design principles, website usability, user-friendly website, usability testing, prototyping, Mockups'}
                ogKeyword={'user interface design, user experience design, user-centered design, intuitive interface, UX/UI design services, mobile app UX/UI design, visual design, design principles, website usability, user-friendly website, usability testing, prototyping, Mockups'}

                title={'UX/UI Design services for Web and Mobile applications | KHG tec'}
                ogTitle={'UX/UI Design services for Web and Mobile applications | KHG tec'}

                description={'Our user-centered approach and rigorous usability testing guarantee optimal user experiences through design principles and user-friendly interfaces.'}
                ogDescription={'Our user-centered approach and rigorous usability testing guarantee optimal user experiences through design principles and user-friendly interfaces.'}
            />




            <UiUxDesignHeader/>
            <Suspense fallback={null}>
                <UiUxDesignExamples/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...designText}/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg  {...designResponsiveText}/>
            </Suspense>
            <Suspense fallback={null}>
                <BlogSlider type={'design'}/>
            </Suspense>
        </>

    );
}

export default UiUxDesignPage;