import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./FAQHeader.module.scss";

import {
    FAQHeaderBig, FAQHeaderBigBlur,
    FAQHeaderBigWebp, FAQHeaderBigWebpBlur,
    FAQHeaderTab, FAQHeaderTabBlur,
    FAQHeaderTabWebp, FAQHeaderTabWebpBlur

} from "../../../assets/images";
import React, {useEffect, useState} from "react";


function FAQHeader() {
    const [windowSize, setWindowSize] = useState('')
    const [imgLoaded,setImgLoaded] = useState(false)

    useEffect(() => {
        changeWindowSize()
        window.addEventListener('resize', changeWindowSize)

        return () => {
            window.removeEventListener('resize', changeWindowSize)
        }
    }, [])

    const changeWindowSize = () => {
        if (window.innerWidth <= 768) {
            setWindowSize('tab')
        } else {
            setWindowSize('desk')
        }
    }

    const onImgLoad = () => setImgLoaded(true)


    return (
        <header className={styles['FAQHeader']}>
            {windowSize === 'desk' &&
                <>
                    <ImageWebp
                        srcSet={FAQHeaderBigWebp}
                        src={FAQHeaderBig}
                        alt="FAQ"
                        onLoad={onImgLoad}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["FAQHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={FAQHeaderBigWebpBlur}
                        src={FAQHeaderBigBlur}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["FAQHeader__img"]} ${styles["FAQHeader__img_blur"]}`}
                    />
                </>
            }
            {windowSize === 'tab' &&
                <>
                    <ImageWebp
                        srcSet={FAQHeaderTabWebp}
                        src={FAQHeaderTab}
                        alt="FAQ"
                        onLoad={onImgLoad}
                        style={{display: imgLoaded ? 'block' : 'none'}}
                        className={styles["FAQHeader__img"]}
                    />
                    <ImageWebp
                        srcSet={FAQHeaderTabWebpBlur}
                        src={FAQHeaderTabBlur}
                        alt="App development"
                        style={{display: !imgLoaded ? 'block' : 'none'}}
                        className={`${styles["FAQHeader__img"]} ${styles["FAQHeader__img_blur"]}`}
                    />
                </>
            }
            <div className="containerSmall">
                <h2 className={styles['FAQHeader__title']}>Frequently asked questions</h2>
            </div>
        </header>
    )
}

export default FAQHeader