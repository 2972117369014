export const homePagePath = '/'
export const aboutPagePath = '/about'
export const contactPagePath = '/contact'
export const blobPagePath = '/blog'
export const designPagePath = '/web-design'
export const webDevelopmentPath = '/web-development'
export const appDevelopmentPath = '/app-development'
export const seoPagePath = '/seo'
export const privacyPagePath = '/privacy'
export const termsPagePath = '/terms'
export const faqPagePath = '/faq'
export const careerPagePath = '/career'
export const serverErrorPagePath = '/server-error'
