import React, {useEffect, useState} from 'react';

import styles from "./ContactHeader.module.scss"
import {facebookIcon, linkedInIcon, whatsappIcon} from "../../../assets/svg";
import Svg from "../../layout/Svg/Svg";
import {contactMapGif, contactMapGifPoster} from "../../../assets/images";
import {contactLinks, socialLinks} from "../../../assets/UIData";

function ContactHeader() {
    const [headerImage, setHeaderImage] = useState(contactMapGifPoster)

    useEffect(() => {
        setHeaderImage(contactMapGif)
    }, [])

    return (
        <header className={`${styles['contactHeader']} container`}>
            <div className={styles["contactHeader__textBlock"]}>
                <h2 className={`${styles["contactHeader__title"]} headerTitle`}>Contact us today to learn more about our<span
                    className="lightBlueText"> services</span></h2>
                <a href={contactLinks.contactMail} className={styles["contactHeader__link"]}>contact@khgtec.com</a>
                <a href={contactLinks.phone} className={styles["contactHeader__link"]}>+374(94) 06 96 50</a>
                <a target='_blank' rel='noreferrer' href={contactLinks.map} className={styles["contactHeader__link"]}>Armenia,
                    Yerevan, Aram Khachatryan 16/2</a>
                <div className={styles["contactHeader__socIconsBlock"]}>
                    <a
                        href={socialLinks.facebook}
                        target='_blank'
                        rel="noreferrer"
                        className={styles['contactHeader__iconLink']}
                        title='Facebook'
                    ><Svg id={facebookIcon}
                          className={styles['contactHeader__icon']}/></a>
                    <a
                        href={socialLinks.linkedIn}
                        target='_blank'
                        rel="noreferrer"
                        className={styles['contactHeader__iconLink']}
                        title='LinkedIn'
                    ><Svg id={linkedInIcon}
                          className={styles['contactHeader__icon']}/></a>
                    <a
                        href={socialLinks.whatsapp}
                        target='_blank'
                        rel="noreferrer"
                        className={styles['contactHeader__iconLink']}
                        title='WhatsApp'
                    ><Svg id={whatsappIcon}
                          className={styles['contactHeader__icon']}/></a>
                </div>
            </div>
            <div className={styles["contactHeader__imgBlock"]}>
                <a href={contactLinks.map} target='_blank' rel='noreferrer'>
                    <img
                        src={headerImage}
                        alt="map"
                        className={styles["contactHeader__img"]}
                    />
                </a>
            </div>
        </header>
    );
}

export default ContactHeader;