export const baseUrl = 'https://back.khgtec.com';
export const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
        'mode': 'no-cors'
    }
};

export const mainBlogUrl = '/api/v1/blog'
export const subscribeUrl = '/api/v1/subscribe'
export const careerUrl = '/api/v1/career'
export const joinUrl = '/api/v1/cv'
export const contactUrl = '/api/v1/contact'


export const fetchRequest = async (fetchUrl, method = 'GET', body = null, config = baseConfig) => {
    const response = await fetch(`${baseUrl}${fetchUrl}`, {
        method: method,
        body: body,
        ...config
    });
    const resData = await response.json();
    if (!response.ok) {
        // eslint-disable-next-line no-throw-literal
        throw {message: resData, status: response.status};
    }
    return resData
}


export const setError = (payload,type) => dispatch => {
    const error = typeof payload !== 'object' ? 'Something went wrong' : payload
    dispatch({type: type,payload: error})
}