import React from 'react';

import IndBlogWrapper from "../components/IndBlogPage/IndBlogWrapper/IndBlogWrapper";

function IndBlogPage() {
    return (
        <IndBlogWrapper/>
    );
}

export default IndBlogPage;


