import React from 'react';

import styles from "./CareerHeader.module.scss"
import {careerHeaderImg, careerHeaderImgWebp} from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

function CareerHeader() {
    return (
        <header className={`${styles['careerHeader']} container`}>
            <ImageWebp
                pictureClass={styles["careerHeader__picture"]}
                srcSet={careerHeaderImgWebp}
                src={careerHeaderImg}
                alt="Live,Work,Create"
                className={styles["careerHeader__img"]}
            />
            <h3 className={styles["careerHeader__title"]}>Grow Your<span className="lightBlueText"> Career</span> by Joining KHG tec!</h3>
        </header>
    );
}

export default CareerHeader;