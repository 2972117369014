import {useNavigate} from 'react-router-dom'

import SecondaryBtn from "../../layout/SecondaryBtn/SecondaryBtn";

import styles from "./HomeHeader.module.scss"
import {aboutPagePath} from "../../../router/path";
import {useEffect, useState} from "react";

function HomeHeader() {
    const navigate = useNavigate()
    const [splineSrc, setSplineSrc] = useState(null)
    const [splineLoaded,setSplineLoaded] = useState(false)
    useEffect(() => {
        changeSplineSrc()
        window.addEventListener('resize', changeSplineSrc)

        return () => {
            window.removeEventListener('resize', changeSplineSrc)
        }
    }, [])

    const changeSplineSrc = () => {
        const size = window.innerWidth
        if(size > 1800) {
            setSplineSrc('https://my.spline.design/untitled-db267fe3fa9f696520a0d942875c34bb/')
        } else if (size < 1800 && size >= 1550) {
            setSplineSrc('https://my.spline.design/1600-59e5b1afd16e699724e1a5037d684f48/')
        } else if (size < 1550 && size >= 1200) {
            setSplineSrc('https://my.spline.design/1500-1dfa5921bf5de79b8454267039b4e379/')
        } else if (size < 1200 && size >= 1025) {
            setSplineSrc('https://my.spline.design/1200-dfc0c5df2bf2a682078608aad6cbf111/')
        } else if (size < 1025 && size >= 860) {
            setSplineSrc('https://my.spline.design/tablet-10ade4fde50eabd33772e2b68920575d/')
        } else if (size < 860 && size >= 820) {
            setSplineSrc('https://my.spline.design/tablet2-5ef49c8d5b78aa7a877a87eea5c79701/')
        } else if (size < 820 && size >= 720) {
            setSplineSrc('https://my.spline.design/phone-df3eff7a8851c3b7289e9478beac6aed/')
        } else if (size < 720 && size >= 450) {
            setSplineSrc('https://my.spline.design/phone2-9fae0c9ced027828e6901f404f044a72/')
        } else if (size < 450 && size > 340) {
            setSplineSrc('https://my.spline.design/phone3-3632f7f616b5d339d01c3eaa42520a24/')
        } else
            if (size < 340) {
            setSplineSrc('https://my.spline.design/phone4-6091c852b020f721cdfa792271ef849c/')
        }
    }
    const onClickLearnMore = () => {
        navigate(aboutPagePath)
    }

    const onSplineLoad = () => {
      setSplineLoaded(true)

    }
    return (
        <header className={styles['homeHeader']}>
            <div className={`${styles['homeHeader__container']} container`}>
                <div className={styles["homeHeader__texts"]}>
                    <h2 className={`${styles["homeHeader__title"]} headerTitle`}>Where creativity meets<span
                        className='lightBlueText'> technology</span></h2>
                    <p className={`${styles["homeHeader__contentText"]} contentTextDef`}>The interaction between technology and creativity is a dynamic and constantly evolving one, with each influencing and shaping the other in exciting and unexpected ways.
                    </p>
                    <p className={`${styles["homeHeader__contentText"]} contentTextDef`}>
                        By combining creative thinking with technical expertise, we develop innovative solutions.
                    </p>
                    <SecondaryBtn onClick={onClickLearnMore} className={styles['homeHeader__btn']}>Learn
                        More</SecondaryBtn>
                </div>
                <div className={styles["homeHeader__animation"]}>
                    <div className={styles['homeHeader__splineWrapper']} style={{display: splineLoaded ? 'block' : 'none'}}>
                    {splineSrc && <iframe
                        src={splineSrc}
                        frameBorder='0'
                        width='100%'
                        height='100%'
                        title='animation'
                        className={styles['homeHeader__spline_desk']}
                        onLoad={onSplineLoad}
                    ></iframe>}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeHeader;