import React, {lazy} from 'react';

const ServerErrorBlock = lazy(() => import("../components/ServerErrorPage/ServerErrorBlock/ServerErrorBlock"));

function ServerErrorPage() {
    return (
        <ServerErrorBlock/>
    );
}

export default ServerErrorPage;