import React, {lazy, Suspense} from 'react';
import SeoHeader from "../components/SeoPage/SeoHeader/SeoHeader";
import {seoUxWritingTexts} from "../assets/UIData";
import Head from '../components/global/Head/Head'

const SeoServicesBlock = lazy(() => import("../components/SeoPage/SeoServicesBlock/SeoServicesBlock"));
const WrapperWithImg = lazy(() => import("../components/layout/WrapperWithImg/WrapperWithImg"));
const BlogSlider = lazy(() => import("../components/global/BlogSlider/BlogSlider"));

function SeoPage() {
    return (
        <>
            <Head
                keyword={'SEO services, On-Page SEO, technical SEO, SEO analytics, SEO strategy, Google Rankings, search engine optimization, search engine rankings, website optimization, UI copy, UX content strategy, UX writing, brand voice and tone '}
                ogKeyword={'SEO services, On-Page SEO, technical SEO, SEO analytics, SEO strategy, Google Rankings, search engine optimization, search engine rankings, website optimization, UI copy, UX content strategy, UX writing, brand voice and tone '}

                title={'SEO services: Google Rankings, UX content strategy | KHG tec'}
                ogTitle={'SEO services: Google Rankings, UX content strategy | KHG tec'}

                description={'Our services encompass expert website optimization, strategic SEO planning, thorough SEO analytics, and effective UX writing, among other offerings.'}
                ogDescription={'Our services encompass expert website optimization, strategic SEO planning, thorough SEO analytics, and effective UX writing, among other offerings.'}
            />

            <SeoHeader/>
            <Suspense fallback={null}>
                <SeoServicesBlock/>
            </Suspense>
            <Suspense fallback={null}>
                <WrapperWithImg {...seoUxWritingTexts}/>
            </Suspense>
            <Suspense fallback={null}>
                <BlogSlider type={'seo'}/>
            </Suspense>
        </>
    );
}

export default SeoPage;