import React, {lazy, Suspense} from 'react';
import ContactHeader from "../components/ContactPage/ContactHeader/ContactHeader";
import Head from '../components/global/Head/Head'

const ContactForm = lazy(() => import("../components/ContactPage/ContactForm/ContactForm"));

function ContactPage() {
    return (
        <>
            <Head
                keyword={'contact us, contact information, social media contacts, find us, support team, Armenian tech companies, IT company in Yerevan, phone number, email address, company address'}
                ogKeyword={'contact us, contact information, social media contacts, find us, support team, Armenian tech companies, IT company in Yerevan, phone number, email address, company address'}

                title={'Contact Us for Web and Mobile Development Services | KHG tec'}
                ogTitle={'Contact Us for Web and Mobile Development Services | KHG tec'}

                description={'Get in touch with us at contact@khgtec.com or call us at +374(94) 06 96 50. Reach out to our team for any questions you may have about our services.'}
                ogDescription={'Get in touch with us at contact@khgtec.com or call us at +374(94) 06 96 50. Reach out to our team for any questions you may have about our services.'}
            />

            <ContactHeader/>
            <Suspense fallback={null}>
                <ContactForm/>
            </Suspense>
        </>
    );
}

export default ContactPage;