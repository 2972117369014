
export const onClickOutSide = (ref, onCloseState, state) => {
    const checkIfClickedOutside = (e) => {
        const isRef = ref.every(value => value.current
            && !value.current.contains(e.target)
            && !e.target.classList.contains('react-calendar__tile')
            && e.target.tagName !== 'ABBR'
        )
        if (state && isRef) {
            onCloseState()
        }
    }
    document.addEventListener('click', checkIfClickedOutside)

    return () => {
        document.removeEventListener('click', checkIfClickedOutside)
    }
}

///////

export const setActiveNavLinkClass = (defaultClass,activeClass) => {
    return ({isActive}) => (isActive ? `${defaultClass} ${activeClass}` : defaultClass)
}

///////

export const lazyLoad = (setState,ref) => {
    const onLazyLoad = function (entries) {
        const [entry] = entries;
        if (entry.isIntersecting) {
            setState()
        }
    };
    const menuObserver = new IntersectionObserver(onLazyLoad, {
        root: null,
        threshold: 0,
    });
    menuObserver.observe(ref.current);
}

export const scrollTop = (isSmooth) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: isSmooth ? "smooth" : 'instant'
    });
}

export const replaceDateFormat = (date) => {
    if (date) {
        return date.split('-').reverse().join('/')
    }
}