import {
    CLEAR_CONTACT_ERROR,
    CLEAR_CONTACT_MESSAGE,
    CONTACT_FAIL,
    CONTACT_LOADING_START,
    CONTACT_SUCCESS
} from "../types";


const initialState = {
    success: false,
    error: null,
    loading: false,
}

export const contactReducer = (state = initialState,action) => {
    const {type,payload} = action
    switch (type) {
        case CONTACT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
            }
        case CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case CONTACT_LOADING_START:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_CONTACT_MESSAGE:
            return {
                ...state,
                success: false,
            }
        case CLEAR_CONTACT_ERROR:
            return {
                ...state,
                error: null,
            }
        default:
            return state
    }
}