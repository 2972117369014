import React, {lazy, Suspense} from "react";
import Head from '../components/global/Head/Head';

const TermsText = lazy(() => import("../components/PolicyTermsTexts/TermsText"));

function TermsPage() {

    return (
        <>
            <Suspense fallback={null}>
                <TermsText/>
            </Suspense>

            <Head
                keyword={'Terms and Conditions, Terms of Use, Terms of Service, Legal agreements, User agreement, Data protection, Intellectual property rights, Copyright notice, Content ownership, Governing law, Contractual obligations, User responsibilities, Prohibited activities, Dispute resolution'}
                ogKeyword={'Terms and Conditions, Terms of Use, Terms of Service, Legal agreements, User agreement, Data protection, Intellectual property rights, Copyright notice, Content ownership, Governing law, Contractual obligations, User responsibilities, Prohibited activities, Dispute resolution'}

                title={'Websites, Mobile/Desktop Apps Terms of Use Agreement | KHG tec'}
                ogTitle={'Websites, Mobile/Desktop Apps Terms of Use Agreement | KHG tec'}

                description={'Stay informed about our policies, detailed usage guidelines, rights, and legal obligations to ensure a compliant understanding in our collaboration.'}
                ogDescription={'Stay informed about our policies, detailed usage guidelines, rights, and legal obligations to ensure a compliant understanding in our collaboration.'}
            />
        </>
    );
}

export default TermsPage;