import {
    CLEAR_JOIN_ERROR,
    CLEAR_JOIN_MESSAGE,
    JOIN_FAIL,
    JOIN_LOADING_START,
    JOIN_SUCCESS
} from "../types";


const initialState = {
    success: false,
    error: null,
    loading: false,
}

export const joinReducer = (state = initialState,action) => {
    const {type,payload} = action
    switch (type) {
        case JOIN_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
            }
        case JOIN_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case JOIN_LOADING_START:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_JOIN_MESSAGE:
            return {
                ...state,
                success: false,
            }
        case CLEAR_JOIN_ERROR:
            return {
                ...state,
                error: null,
            }
        default:
            return state
    }
}