import React, {useRef} from 'react';

import styles from "./BlogHeader.module.scss"
import {blogHeaderList, blogHeaderListWebp, blogHeaderUser} from "../../../assets/images"
import {useImageSlideAnimation} from "../../../hooks/useImageSlideAnimation";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {useDispatch, useSelector} from "react-redux";
import {SET_BLOG_HEADER_IMAGE_HEIGHT} from "../../../redux/types";

function BlogHeader() {
    const dispatch = useDispatch()
    const blogHeaderImageHeight = useSelector(state => state.UI.blogHeaderImageHeight)
    const imgHeaderRef = useRef(null)

    const setBlogHeaderImageHeight = (payload) => dispatch({type: SET_BLOG_HEADER_IMAGE_HEIGHT,payload})

    const {
        imgRef,
        imgListHeight,
        changeImgListHeight,
        ImageSlideAnimation
    } = useImageSlideAnimation(
        blogHeaderImageHeight,
        setBlogHeaderImageHeight,
        2,
        imgHeaderRef.current?.getBoundingClientRect().height
    )

    const onLoadImg = e => {
        changeImgListHeight(2,imgHeaderRef.current?.getBoundingClientRect().height)
        if (window.innerWidth > 576) {
            ImageSlideAnimation(e, 5000, '-50% - 13px')
        } else {
            ImageSlideAnimation(e, 5000, '-50% - 6px')
        }
    }


    return (
        <header className={`${styles['blogHeader']} container`}>
            <div className={styles["blogHeader__texts"]}>
                <h2 className={`${styles["blogHeader__title"]} headerTitle`}><span
                    className={'lightBlueText'}>Tech news</span> you'll actually want to read!</h2>
                <p className={`${styles["blogHeader_contentText"]} contentTextDef`}>Stay up-to-date with the latest in technology and innovation with our informative tech blog.</p>
            </div>
            <div className={styles["blogHeader__imgBlock"]} style={{height: imgListHeight}}>
                <div className={styles["blogHeader__imgBlockHeader"]} ref={imgHeaderRef}>
                    <div className={styles["blogHeader__userInfo"]}>
                        <img
                            src={blogHeaderUser}
                            alt="user"
                            className={styles["blogHeader__userImg"]}
                        />
                        <p className={styles["blogHeader__username"]}>Karolina Melkonyan</p>
                    </div>
                    <p className={styles["blogHeader__date"]}>01/01/2023</p>
                </div>
                <ImageWebp
                    forwardedRef={imgRef}
                    srcSet={blogHeaderListWebp}
                    src={blogHeaderList}
                    alt="blog list"
                    className={styles["blogHeader__img"]}
                    onLoad={onLoadImg}
                />
            </div>
        </header>
    );
}

export default BlogHeader;
