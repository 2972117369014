import {useEffect, useRef} from "react";


export const useImageSlideAnimation = (imgListHeight,setImgListHeight,heightSliceCount, addHeight = 0) => {
    const imgRef = useRef(null)

    let slideDownInterval, slideUpInterval, slideDownTimeOut, slideImageTimeOut

    useEffect(() => {
            window.addEventListener('resize', onResize)
            return () => {
                window.removeEventListener('resize', onResize)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addHeight])


    const onResize = () => changeImgListHeight(heightSliceCount, addHeight)
    const changeImgListHeight = (heightSliceCount, addHeight = 0) => {
        setImgListHeight(imgRef.current.getBoundingClientRect().height / heightSliceCount + addHeight)
    }


    const ImageSlideAnimation = (e, dur, bottomDistance = '0px', delay = 500) => {
        const slideImage = () => {
            const intervalDuration = dur * 2 + delay * 2
            const timeoutDuration = intervalDuration / 2
            const slideDown = () => {
                e.target.style.transform = `translateY(calc(${bottomDistance}))`
            }
            const slideUp = () => {
                e.target.style.transform = 'translateY(0%)'
            }
            slideDown()
            slideDownInterval = setInterval(slideDown, intervalDuration)
            slideDownTimeOut = setTimeout(() => {
                slideUp()
                slideUpInterval = setInterval(slideUp, intervalDuration)
            }, timeoutDuration)

        }
        slideImageTimeOut = setTimeout(() => slideImage(e, dur, bottomDistance, delay), 1000)
    }

    useEffect(() => {
        return (() => {
            clearInterval(slideDownInterval)
            clearInterval(slideUpInterval)
            clearTimeout(slideDownTimeOut)
            clearTimeout(slideImageTimeOut)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        imgRef,
        imgListHeight,
        changeImgListHeight,
        ImageSlideAnimation,
    }
}
