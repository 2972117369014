import {lazy, Suspense} from "react";

import FAQHeader from '../components/FAQ/FAQHeader/FAQHeader';

import {FAQAccordionArr} from '../assets/UIData';

import Head from '../components/global/Head/Head';

const Accordion = lazy(() => import('../components/layout/Accordion/Accordion'));


function FAQPage() {
    return (
        <>
            <Head
                keyword={'web development FAQs, mobile development FAQs, website pricing and packages, website development timeline, mobile app development time, professional content writing, mobile-friendly website, website maintenance services, website migration, iOS and Android app development, Google ranking, tech consulting, UX/UI design services, DevOps security'}
                ogKeyword={'web development FAQs, mobile development FAQs, website pricing and packages, website development timeline, mobile app development time, professional content writing, mobile-friendly website, website maintenance services, website migration, iOS and Android app development, Google ranking, tech consulting, UX/UI design services, DevOps security'}

                title={' FAQs of Web and Mobile App Development Services | KHG tec'}
                ogTitle={' FAQs of Web and Mobile App Development Services | KHG tec'}

                description={'Do you redesign existing websites? - 2. Can You Build Apps for Both iOS and Android? - 3.Do you do SEO for my website when you build it?'}
                ogDescription={'Do you redesign existing websites? - 2. Can You Build Apps for Both iOS and Android? - 3.Do you do SEO for my website when you build it?'}
            />


            <FAQHeader/>
            <div>
                {FAQAccordionArr.map((item, index) => (
                    <Suspense fallback={null}>
                        <Accordion {...item} key={index} index={index}/>
                    </Suspense>
                ))}
            </div>
        </>
    );
}

export default FAQPage;