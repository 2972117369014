import {
    GET_ALL_BLOGS_FAIL,
    GET_ALL_BLOGS_LOADING_START,
    GET_ALL_BLOGS_SUCCESS,
    SET_BLOG_SCROLL_POSITION
} from "../types";


const initialState = {
    data: [],
    designBlogs: [],
    webBlogs: [],
    appBlogs: [],
    seoBlogs: [],
    otherBlogs: [],
    loading: false,
    error: null,
    nextPage: false,
    currPage: 1,
    scrollPosition: 0
}

export const mainBlogReducer = (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case GET_ALL_BLOGS_SUCCESS :
            return {
                ...state,
                [payload?.type]: payload.data,
                nextPage: payload.nextPage,
                currPage: payload.currPage,
                loading: false,
            }

        case GET_ALL_BLOGS_FAIL :
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case GET_ALL_BLOGS_LOADING_START :
            return {
                ...state,
                loading: true,
            }
        case SET_BLOG_SCROLL_POSITION :
            return {
                ...state,
                scrollPosition: payload,
            }
        default:
            return state
    }
}
