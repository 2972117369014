import React from 'react';

import styles from './IndBlogUserBlock.module.scss'
import {blogHeaderUser} from "../../../assets/images";
import {useSelector} from "react-redux";
import {replaceDateFormat} from "../../../globalFunctions";

function IndBlogUserBlock({className}) {
    const {data} = useSelector(state => state.indBlog)
    const date = replaceDateFormat(data?.date)

    return (
        <div className={`${styles["indBlogUserBlock"]} ${className ? className : ''}`}>
            <img src={blogHeaderUser} alt="user" className={styles["indBlogUserBlock__img"]}/>
            <div className={styles["indBlogUserBlock__info"]}>
                <p className={styles["indBlogUserBlock__name"]}>Karolina Melkonyan</p>
                <p className={`${styles["indBlogUserBlock__date"]} contentTextDef`}>{date && date}</p>
            </div>
        </div>
    );
}

export default IndBlogUserBlock;