import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";

import Svg from "../../layout/Svg/Svg";
import MainBtn from "../../layout/MainBtn/MainBtn";

import styles from "./Navbar.module.scss"
import {arrowDownIcon, burgerIcon, navLogoIcon} from "../../../assets/svg"
import NavbarServicesDropdown from "./NavbarServicesDropdown/NavbarServicesDropdown";
import TransitionProvider from "../../../providers/TransitionProvider";
import {
    aboutPagePath,
    appDevelopmentPath,
    blobPagePath,
    designPagePath, seoPagePath,
    webDevelopmentPath,
    homePagePath, careerPagePath, contactPagePath
} from "../../../router/path";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import {scrollTop, setActiveNavLinkClass} from "../../../globalFunctions";

function Navbar() {
    const location = useLocation()
    const navigate = useNavigate()
    const [servicesDropdownOpened, setServicesDropdownOpened] = useState(false)
    const [burgerOpened, setBurgerOpened] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const dropBtnRef = useRef(null)
    const navbarWhiteState = location.pathname === designPagePath || location.pathname === seoPagePath

    useEffect(() => {
        checkIsScrolled()
        window.addEventListener('scroll', checkIsScrolled)

        return () => {
            window.removeEventListener('scroll', checkIsScrolled)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const checkIsScrolled = () => {
        if (window.pageYOffset > 20 && !isScrolled) {
            setIsScrolled(true)
        } else if (window.pageYOffset <= 20) {
            setIsScrolled(false)
        }
    }

    const toggleServicesDropdown = () => setServicesDropdownOpened(prevState => !prevState)
    const closeServicesDropdown = useCallback(() => setServicesDropdownOpened(false), [])
    const openBurger = () => setBurgerOpened(true)
    const closeBurger = useCallback(() => setBurgerOpened(false), [])

    const linkActiveClass = setActiveNavLinkClass(`${styles['navbar__menuLink']} ${navbarWhiteState ? styles['navbar__menuLink_white'] : ''}`, styles['navbar__menuLink_active'])
    let servicesActiveClass = ''

    if (location.pathname.includes(designPagePath) ||
        location.pathname.includes(appDevelopmentPath) ||
        location.pathname.includes(webDevelopmentPath) ||
        location.pathname.includes(seoPagePath)
    ) {
        servicesActiveClass = styles["navbar__menuLink_active"]
    }

    const onClickLogo = () => {
        scrollTop(true)
        closeServicesDropdown()
    }

    const onClickNavLink = () => {
        closeServicesDropdown()
        scrollTop()
    }

    const onClickContactBtn = useCallback(() => {
        navigate(contactPagePath)
        scrollTop()
        // eslint-disable-next-line
    },[])

    return (
        <>
            <div
                className={`${styles['navbar']} ${isScrolled || servicesDropdownOpened ? styles['navbar_active'] : ''} ${burgerOpened ? styles['navbar_disabled'] : ''}`}>
                <div className={`${styles['navbar__container']} container`}>
                    <Link to={homePagePath} onClick={onClickLogo}>
                        <div className={styles["navbar__logoBlock"]}>
                            <Svg
                                className={`${styles["navbar__logoImg"]} ${navbarWhiteState ? styles["navbar__logoImg_white"] : ''}`}
                                id={navLogoIcon}/>
                        </div>
                    </Link>
                    <nav className={styles["navbar__menu"]}>
                        <NavLink onClick={onClickNavLink} to={aboutPagePath} className={linkActiveClass}>About</NavLink>
                        <div ref={dropBtnRef} onClick={toggleServicesDropdown}
                             className={`${styles["navbar__menuLink"]} ${servicesActiveClass} ${navbarWhiteState ? styles['navbar__menuLink_white'] : ''}`}>
                            <span className={styles["navbar__menuLinkText"]}>Services</span>
                            <Svg
                                className={`${styles['navbar__menuLinkIcon']} ${servicesDropdownOpened ? styles['navbar__menuLinkIcon_active'] : ''}`}
                                id={arrowDownIcon}/>
                        </div>
                        <NavLink onClick={onClickNavLink} to={blobPagePath} className={linkActiveClass}>Blog</NavLink>
                        <NavLink onClick={onClickNavLink} to={careerPagePath}
                                 className={linkActiveClass}>Careers</NavLink>
                        <MainBtn onClick={onClickContactBtn}>Contact</MainBtn>
                    </nav>
                    <button className={styles["navbar__burgerBtn"]} onClick={openBurger}>
                        <Svg className={styles["navbar__burgerBtnIcon"]} id={burgerIcon}/>
                    </button>
                </div>
                <TransitionProvider
                    duration={300}
                    inProp={servicesDropdownOpened}
                    // eslint-disable-next-line react/style-prop-object
                    style={'rotateX'}
                >
                    <NavbarServicesDropdown
                        closeServicesDropdown={closeServicesDropdown}
                        servicesDropdownOpened={servicesDropdownOpened}
                        dropBtnRef={dropBtnRef}
                        closeBurger={closeBurger}
                    />
                </TransitionProvider>
            </div>
            <BurgerMenu
                burgerOpened={burgerOpened}
                closeBurger={closeBurger}
            />
        </>

    );
}

export default Navbar;
